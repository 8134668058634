import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';

export default apiUrl => (type, resource, params) => {
  const options = {};

  const token = Cookies.get('accessToken');
  if (token) {
    options.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  switch (type) {
    case 'create': {
      options.url = `${apiUrl}/${resource}`;
      options.method = 'POST';
      options.data = qs.stringify(params.data);
      break;
    }
    case 'partial_update': {
      options.url = `${apiUrl}/${resource}/${params.id}`;
      options.method = 'PATCH';
      options.data = qs.stringify(params.data);
      break;
    }
    case 'delete': {
      options.url = `${apiUrl}/${resource}/${params.id}`;
      options.method = 'DELETE';
      break;
    }
    case 'getOne': {
      options.url = `${apiUrl}/${resource}/${params.id}`;
      options.method = 'GET';
      break;
    }
    case 'list':
    case 'get':
    default: {
      options.baseURL = apiUrl;
      options.url = `/${resource}`;
      options.method = 'GET';
      if (params && params.query) {
        options.params = params.query;
      }
      break;
    }
  }

  return axios.request(options)
    .then((response) => {
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }

      return response.data;
    })
    .catch(err => Promise.reject(err));
};
