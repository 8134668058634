import React from 'react';

const BackButton = ({ clickHandler }) => (
  <button onClick={clickHandler} className="basket-back-button">
    <svg
      width="24"
      height="19"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 19"
    >
      <path className="PathIcon-sc-1qr0hpz-1 ckUTib PathIcon__Path-sc-1qr0hpz-0 hFTvWf" width="24" height="19" d="M9.4707 0H10.8848L1.88477 9H24V10H1.94336L10.9434 19H9.5293L0 9.4707L9.4707 0Z" viewBox="0 0 24 19" fill="black" />
    </svg>
  </button>
);

export default BackButton;
