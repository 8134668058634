import React from 'react';
import LoginForm from './LoginForm';
import RegistrationForm from './RegistrationForm';
import useAuth from '../hooks/useAuth';
import ActivityIndicator from './ActivityIndicator';

const SwitchForm = ({ activeForm }) => {
  switch (activeForm) {
    case 'loginForm':
      return (
        <LoginForm />
      );
    case 'registrationForm':
      return (
        <RegistrationForm />
      );
    default:
      return (
        <LoginForm />
      );
  }
};

const LoginModal = () => {
  const { activeForm, loading } = useAuth();

  return (
    <div className="modal modal-user fade" id="modal-auth" tabIndex="-1" role="dialog" aria-hidden="false">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <ActivityIndicator loading={loading} />
          <button className="close" data-dismiss="modal">&times;</button>
          <SwitchForm activeForm={activeForm} />
        </div>
      </div>
    </div>
  );
};

export default LoginModal;
