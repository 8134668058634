import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import * as moment from 'moment';
import util from 'util';
import { ClipLoader } from 'react-spinners';
import { useNavigate, useSearchParams } from 'react-router-dom';
import 'moment/locale/mn';
import RestDataProvider from '../dataProvider/RestDataProvider';
import useHub from '../hooks/useHub';

const CandyPayConfirmation = () => {
  const [order, setOrder] = useState({ lineItems: [] });
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { hub } = useHub();

  useEffect(() => {
    if (searchParams.get('status') !== 'PAID') {
      navigate('/');
    }

    const dataProvider = RestDataProvider(process.env.REACT_APP_API_URL);

    const url = util.format('checkout/candy');
    dataProvider(
      'get',
      url,
      {
        query: {
          amount: searchParams.get('amount'),
          invoiceId: searchParams.get('invoiceId'),
          status: searchParams.get('status'),
          tnxId: searchParams.get('tnxId'),
          info: searchParams.get('info'),
        },
      },
    )
      .then((result) => {
        if (!result) {
          return;
        }

        setOrder(result);
        setLoading(false);
      })
      .catch(() => {
        navigate('/');
      });
  }, []);

  const handleBackButton = () => {
    navigate('/');
  };

  const {
    id,
    lineItems,
    deliverySlot,
    deliveryMethod,
  } = order;

  if (loading) {
    return (
      <>
        <div className="title">
          <h4>
            <i className="fa fa-money" />
            Захиалгын дэлгэрэнгүй
          </h4>
        </div>
        <div className="content order-information pb-0">
          <ClipLoader
            css={{
              cursor: 'pointer',
              display: 'inline-block',
              marginLeft: '-24',
              marginTop: '-24',
              left: '50%',
              pointerEvents: 'none',
              position: 'absolute',
              top: '50%',
              verticalAlign: 'middle',
            }}
            size={48}
            color="#ffa500"
            loading
          />
        </div>
      </>
    );
  }

  return (
    <>
      <div className="title">
        <h4>
          <i className="fa fa-money" />
          Захиалгын дэлгэрэнгүй
        </h4>
      </div>
      <div className="content order-information pb-0">
        <div className="alert alert-success" role="alert">
          <h4 className="alert-heading">
            <i className="fa fa-check-circle" />
            {' '}
            Захиалга амжилттай баталгаажлаа !
          </h4>
          <p>
            <b>
              { 'Захиалгын дугаар: '}
              <span className="badge badge-success">{ id }</span>
            </b>
          </p>
          <p>
            <h5>
              <FormattedMessage id="payment_distribution_day" />
              :
            </h5>
            <strong>
              {`${moment(deliverySlot.openAt).format('L')},  ${moment(deliverySlot.openAt).format('dddd')},`}
            </strong>
            &nbsp;
            {`${moment(deliverySlot.openAt).format('H')} - ${moment(deliverySlot.closeAt).format('H')} цагийн хооронд`}
          </p>
          <p>
            <h5>
              <FormattedMessage id="payment_venue_address" />
              :
            </h5>
            { deliveryMethod.name === 'pick_up_from_hub' ? hub?.venue?.address : deliveryMethod.addressdeliveryAddress }
          </p>

        </div>
        <table className="table table-sm">
          <thead>
            <tr>
              <th scope="col">Бүтээгдэхүүний нэр</th>
              <th scope="col">Тоо</th>
              <th scope="col">Үнэ</th>
            </tr>
          </thead>
          <tbody>
            { lineItems.map(lineItem => (
              <tr>
                <td>{lineItem.productName}</td>
                <td>{lineItem.quantity}</td>
                <td>{lineItem.displayTotal}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="footer mt-auto">
        <div>
          <button type="button" onClick={handleBackButton} className="btn btn-block btn-success" data-dismiss="modal">Нүүр хуудас руу очих</button>
        </div>
      </div>
    </>
  );
};

export default CandyPayConfirmation;
