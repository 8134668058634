import useSWR from 'swr';
import axios from 'axios';

const fetchWithToken = (url, token) => axios.get(url, {
  headers: {
    Authorization: `Bearer ${token}`,
  },
}).then(res => res.data);

function useUserOrders(userId, accessToken) {
  const shouldFetch = userId && accessToken;
  const { data, mutate, error } = useSWR(
    shouldFetch ? [`${process.env.REACT_APP_API_URL}/users/${userId}/orders`, accessToken] : null,
    ([url, t]) => fetchWithToken(url, t),
  );

  const loading = !data && !error;
  const loggedOut = error && error.status === 403;

  return {
    loading,
    loggedOut,
    userOrders: data,
    mutate,
  };
}

export { useUserOrders };
