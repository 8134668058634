import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import ProductList from './ProductList';
import ProductCategory from './ProductCategory';
import useProducts from '../hooks/useProducts';
import useHub from '../hooks/useHub';
import useProductCategories from '../hooks/useProductCategories';
import ActivityIndicator from './ActivityIndicator';

const FilteredProducts = () => {
  const { slug } = useParams();
  const { hubId } = useHub();
  const { categories } = useProductCategories(hubId);
  const {
    products, marketId, isLoading, isError,
  } = useProducts(hubId);

  if (!products) {
    return '';
  }

  if (isLoading) return <ActivityIndicator loading />;
  if (isError) return null;

  const filteredProducts = products?.filter(product => product.type === slug);

  return (
    <main className="main-content">
      <section id="section-buy-now" className="section section-buy-now pb-0">
        <div className="container">
          <div className="section-title mt-1 mb-4">
            <h2 className="title">
              {' '}
              <FormattedMessage id={slug} />
            </h2>
          </div>
          <ProductList
            products={filteredProducts}
            marketId={marketId}
            filter={slug}
          />
        </div>
      </section>
      <section className="section">
        <div className="container">
          { categories?.length > 0
              && <ProductCategory productCategories={categories} />
          }
        </div>
      </section>
    </main>
  );
};

export default FilteredProducts;
