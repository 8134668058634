import React, { useState } from 'react';
import util from 'util';
import { Formik } from 'formik';
import * as Yup from 'yup';
import RestDataProvider from '../dataProvider/RestDataProvider';
import useBasket from '../hooks/useBasket';

const Coupon = ({ coupon, getPayment }) => {
  const { basket, fetchBasket } = useBasket();
  const [couponAlertText, setCouponAlertText] = useState(null);
  const [couponAlertColor, setCouponAlertColor] = useState('text-danger');

  const handleCoupon = (couponCode) => {
    if (!!coupon) {
      setCouponAlertText('Та купон ашигласан байна');

      return;
    }

    const dataProvider = RestDataProvider(process.env.REACT_APP_API_URL);
    const url = util.format('orders/%d/coupon', basket.id);

    dataProvider('create', url, { data: { code: couponCode } })
      .then(() => {
        fetchBasket();
        getPayment();
        setCouponAlertText('Амжилттай баталгаажлаа');
        setCouponAlertColor('text-success');
      })
      .catch(() => {
        setCouponAlertText('Буруу код эсвэл хугацаа дууссан');
      });
  };

  const isCoupon = !!coupon;

  return (
    <Formik
      initialValues={{
        couponCode: '',
      }}
      onSubmit={(values) => {
        handleCoupon(values.couponCode.toUpperCase());
      }}
      validationSchema={Yup.object().shape({
        couponCode: Yup.string()
          .max(20, 'Урт нь хамгийн ихдээ 20 байна')
          .required('Кодоо оруулна уу'),
      })}
    >
      {({
        handleSubmit,
        handleChange,
        errors,
        values,
      }) => (
        <>
          <form onSubmit={handleSubmit}>
            <div className="card border-success">
              <div className="card-header">
                <div className="mb-0">
                  <div className="custom-control custom-checkbox">
                    <input className="custom-control-input" type="checkbox" id="check-coupon" data-toggle="collapse" data-target="#coupon-collapse" disabled={isCoupon} defaultChecked={isCoupon} />
                    <label className="custom-control-label" htmlFor="check-coupon">
                      Купоны хөнгөлөлт оруулах
                    </label>
                  </div>
                </div>
              </div>
              <div id="coupon-collapse" className={`collapse ${isCoupon && 'show'}`}>
                <div className="card-body">
                  <div className="input-group">
                    <input
                      type="text"
                      name="couponCode"
                      className="form-control text-uppercase"
                      onChange={handleChange}
                      value={values.couponCode}
                      maxLength="20"
                      disabled={isCoupon}
                      placeholder={!!isCoupon ? coupon.code : 'Код'}
                    />
                    <div className="input-group-append">
                      <button className="btn btn-outline-secondary" type="submit" disabled={isCoupon}>Оруулах</button>
                    </div>
                  </div>
                  <small className={`form-text mt-0 ${couponAlertColor}`}>{errors.couponCode ? errors.couponCode : couponAlertText}</small>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </Formik>
  );
};

export default Coupon;
