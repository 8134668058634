import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const ConfirmedOrderBanner = () => {
  const navigate = useNavigate();
  const { userOrders } = useAuth();

  const confirmedOrder = userOrders?.find(order => order.status === 'order.confirmed') || null;

  const handleClick = (event) => {
    event.preventDefault();

    navigate('/orders');
  };

  const handleClose = (event) => {
    event.stopPropagation();
  };

  if (!confirmedOrder) {
    return null;
  }

  return (
    <div onClick={e => handleClick(e)} className="alert alert-success alert-dismissible fade show" role="alert">
      <button type="button" onClick={e => handleClose(e)} className="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      <strong>Захиалга амжилттай баталгаажлаа! </strong>
      Та энд дарж захиалгынхаа дэлгэрэнгүйг харна уу.
    </div>
  );
};

export default ConfirmedOrderBanner;
