import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectProduct } from '../actions';
import ProductBox from './ProductBox';
import useHub from '../hooks/useHub';
import useProducts from '../hooks/useProducts';

const ProductList = ({ products }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hubId } = useHub();
  const { marketId } = useProducts(hubId);

  const onSelect = (selectedProduct, event) => {
    event.preventDefault();

    dispatch(selectProduct(selectedProduct));
    navigate(`/hubs/${hubId}/products/${selectedProduct.uuid}`);
  };

  return (
    <div className="row">
      { products?.length > 0 && (
        products.map(product => (
          <ProductBox
            key={product.uuid}
            marketId={marketId}
            product={product}
            onSelect={onSelect}
          />
        ))
      )}
    </div>
  );
};

export default ProductList;
