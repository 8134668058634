import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import sanitizeFilterName from '../utils';
import useHub from '../hooks/useHub';
import useBasket from '../hooks/useBasket';

const ProductBox = ({ product, marketId, onSelect }) => {
  const { hubId } = useHub();
  const { basket, addBasket, handleMinus } = useBasket();

  const renderPrice = () => {
    if (product.isOnSale) {
      return (
        <div className="product-item-card-price">
          <span className="price-value line-through mr-2">
            {product.originalPrice.amount}
            {' '}
            ₮
          </span>
          <span className="price-value on-sale-price">
            {product.unitPrice.amount}
            {' '}
            ₮
          </span>
        </div>
      );
    }

    return (
      <div className="product-item-card-price">
        {' '}
        {product.unitPrice.amount}
        {' '}
        ₮
        {' '}
      </div>
    );
  };

  const unit = <FormattedMessage id={product.unit} />;
  const item = basket?.line_items.find(i => i.productUuid === product.uuid);
  const quantity = !!item ? item.quantity : 0;

  return (
    <div
      key={product.uuid}
      className={
        `col-12 col-sm-6 col-md-4 col-xl-3 mb-4 ${
          sanitizeFilterName(product.producer.name)
        } ${product.type}`
      }
    >
      <div className="product-item-card">
        <div className="product-item-card-image-wrapper product-image">
          <a onClick={e => onSelect(product, e)}>
            <LazyLoad>
              <img
                src={product.imagePath}
                alt={product.name}
                data-product={product.uuid}
                className="b-lazy product-item-card-image product-link-detail-target b-loaded"
              />
            </LazyLoad>
          </a>
        </div>
        <div className="product-item-card-details">
          <div className="product-item-card-action-wrapper">
            { renderPrice() }

            { !product.isSellable
                && (
                  <div className="product-item-card-add-to-basket w-50">
                    <div className="text-right text-danger">Түр хугацаанд гарахгүй</div>
                  </div>
                )
            }

            { !!product.isSellable && !product.isStockSufficient
                && (
                  <div className="product-item-card-add-to-basket w-50">
                    <div className="text-right text-danger">Зарагдаж дууссан</div>
                  </div>
                )
            }

            { !!product.isSellable && product.isStockSufficient && (
              <div className="product-item-card-add-to-basket">
                <div className="product-card-addToBasket">
                  <div className="u-flexible-row addToBasket-buttonContainer">
                    { quantity > 0 && (
                      <>
                        <button
                          type="button"
                          onClick={e => handleMinus(item, quantity, e)}
                          className="addToBasket-button"
                          aria-label="Сагснаас бүтээгдэхүүн хасах"
                        >
                          -
                        </button>
                        <div className="fa-text addToBasket-quantity">{quantity}</div>
                      </>
                    )}
                    <button
                      type="button"
                      onClick={e => addBasket(marketId, product.uuid, e)}
                      className="addToBasket-button addToBasket-add"
                      aria-label="Сагсандаа бүтээгдэхүүн нэмэх"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Link
            className="product-item-card-producer"
            to={`/hubs/${hubId}/producers/${product.producer.uuid}`}
          >
            {product.producer.name}
          </Link>
          <div className="product-item-card-main">
            <Link
              onClick={e => onSelect(product, e)}
              to={`/products/${product.uuid}`}
              className="product-item-card-name product-link-detail-target"
            >
              {' '}
              {product.name}
            </Link>
            { renderPrice(product) }
          </div>
          <div className="product-item-card-stats">
            <div>
              {product.unitValue}
              {' '}
              {unit}
            </div>
            <div />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductBox;
