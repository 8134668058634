import React from 'react';
import { Facebook } from 'react-content-loader';

const MyFacebookLoader = () => <Facebook />;
const ProductsSkeleton = () => (
  <>
    <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-4">
      <MyFacebookLoader />
    </div>
    <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-4">
      <MyFacebookLoader />
    </div>
    <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-4">
      <MyFacebookLoader />
    </div>
  </>
);

export default ProductsSkeleton;
