import React from 'react';
import ReactDOM from 'react-dom';
import AriaModal from 'react-aria-modal';

export default function HowItWorksModal({ onClose }) {
  return ReactDOM.createPortal(
    <AriaModal
      titleText="Хэрхэн ажилладаг вэ ?"
      onExit={onClose}
      focusDialog
      escapeExits
      verticallyCenter
      underlayStyle={{ padding: '10px' }}
      underlayClickExits={false}
    >
      <div className="modal-content">
        <button
          type="button"
          aria-label="Close"
          onClick={onClose}
          className="closeButton"
        >
          <i className="svgIcon">
            <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" fill="#5C272F">
              <path d="M6 4.65L1.626.278C1.26-.09.65-.093.278.277c-.375.376-.373.977 0 1.35L4.65 6 .278 10.374c-.367.367-.37.975 0 1.348.376.375.977.373 1.35 0L6 7.35l4.374 4.373c.367.367.975.37 1.348 0 .375-.376.373-.977 0-1.35L7.35 6l4.373-4.374c.367-.367.37-.975 0-1.348-.376-.375-.977-.373-1.35 0L6 4.65z" fillRule="evenodd" />
            </svg>
          </i>
        </button>
        <div className="modal-body">
          <div className="container pt-4">
            <div className="section-title">
              <h2 className="title">
                Хэрхэн ажилладаг вэ?
              </h2>
            </div>
            <div className="row">
              <div className="col-md-3">
                <div className="how-it-work how-it-work-2">
                  <div className="icon-wrap">
                    <span className="icon" />
                    <span className="number">
                      1
                    </span>
                    <span className="border-1" />
                    <span className="border-2" />
                    <span className="border-3" />
                  </div>
                  <h4>Үйлдвэрлэгчид 7 хоног бүр онлайнаар төрөл бүрийн бүтээгдэхүүнийг танд санал болгоно.</h4>
                </div>
              </div>
              <div className="col-md-3">
                <div className="how-it-work how-it-work-1">
                  <div className="icon-wrap">
                    <div className="icon" />
                    <span className="number">
                      2
                    </span>
                    <span className="border-1" />
                    <span className="border-2" />
                    <span className="border-3" />
                  </div>
                  <h4>Та өөртөө таалагдсан бүтээгдэхүүнээ захиална: жимс, хүнсний ногоо, талх, бяслаг, мах, сүү сүүн бүтээгдэхүүн ... гэх мэт.</h4>
                </div>
              </div>
              <div className="col-md-3">
                <div className="how-it-work how-it-work-3">
                  <div className="icon-wrap">
                    <div className="icon" />
                    <span className="number">
                      3
                    </span>
                    <span className="border-1" />
                    <span className="border-2" />
                    <span className="border-3" />
                  </div>
                  <h4>Үйлдвэрлэгчид бүтээгдэхүүнээ танд зориулж бэлдээд шинэхэнээр нь Монгол Шимийн Үүрэнд авчирна.</h4>
                </div>
              </div>
              <div className="col-md-3">
                <div className="how-it-work how-it-work-4">
                  <div className="icon-wrap">
                    <div className="icon" />
                    <span className="number">
                      4
                    </span>
                    <span className="border-1" />
                    <span className="border-2" />
                    <span className="border-3" />
                  </div>
                  <h4>Та захиалгаа мөн гэртээ хүргүүлэн авах боломжтой.</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AriaModal>,
    document.body,
  );
}
