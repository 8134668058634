import React, { useEffect } from 'react';
import { Formik } from 'formik';
import $ from 'jquery';
import { useNavigate } from 'react-router-dom';
import LineItem from './LineItem';
import EmptyBasket from './EmptyBasket';
import useBasket from '../hooks/useBasket';

const Basket = ({ confirmedOrder, handleNavigationLink, handleLoading }) => {
  const { navigate } = useNavigate();
  const { basket, removeLineItem, handleItemQuantityChange } = useBasket();

  useEffect(() => {
    handleLoading(false);
  }, []);

  const navigateToOrders = () => {
    $('#modal-shopping-cart').modal('hide');

    navigate('/orders');
  };

  if (!basket || !basket.line_items.length) {
    return (
      <EmptyBasket confirmedOrder={confirmedOrder} />
    );
  }

  const lineItems = basket.line_items;

  return (
    <div className="modal-body d-flex flex-column">
      <div className="title">
        {
          !!confirmedOrder
            && (
              <>
                <h4 style={{ lineHeight: '50px', height: '44px' }}>
                  <i className="fa fa-shopping-basket" />
                  Миний сагс
                </h4>
                <button className="btn btn-link p-0" style={{ lineHeight: 1 }} onClick={navigateToOrders}>Миний захиалгууд</button>
              </>
            )
        }
        {
          !confirmedOrder && (
            <h4>
              <i className="fa fa-shopping-basket" />
              Миний сагс
            </h4>
          )
        }
      </div>
      <div className="content">
        <div className="cart-items">
          { lineItems.map(lineItem => (
            <LineItem
              key={lineItem.id}
              item={lineItem}
              handleItemQuantityChange={handleItemQuantityChange}
              removeLineItem={removeLineItem}
            />
          ))}
        </div>
        <hr />
        <div className="cart-price-info">
          <div className="delivery-price d-flex justify-content-between">
            <b>Хүргэлтийн төлбөр:</b>
            <div className="">{ basket.deliveryFee }</div>
          </div>
          <div className="total-price price d-flex justify-content-between">
            <b>Нийт:</b>
            <div className="price">{basket.displayTotal}</div>
          </div>
        </div>
      </div>
      <Formik
        initialValues={{
          totalPrice: 0,
        }}
        onSubmit={(values) => { handleNavigationLink('deliverySlot'); }}
      >
        {({ handleSubmit }) => (
          <div className="footer mt-auto basket-sticky-footer">
            <div>
              <form onSubmit={handleSubmit}>
                <button
                  type="submit"
                  href="#"
                  className="btn btn-block btn-orange ms-modal-navigation"
                >
                  Захиалах
                </button>
              </form>
            </div>
          </div>
        )}
      </Formik>
    </div>
  );
};

export default Basket;
