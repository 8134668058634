import useSWR from 'swr';

const fetcher = (...args) => fetch(...args).then(res => res.json());
function useProducers() {
  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_API_URL}/producers`,
    fetcher,
  );

  return {
    producers: data?.producers,
    isLoading,
    isErorr: error,
  };
}

export default useProducers;
