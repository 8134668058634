import React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import $ from 'jquery';
import UserOrderModal from './UserOrderModal';
import GoBackButton from '../GoBackButton';
import useAuth from '../../hooks/useAuth';

const UserOrders = () => {
  const { userOrders } = useAuth();

  const modalUserOrder = (id, event) => {
    event.preventDefault();
    $(`#modal-user-order${id}`).modal('show');
  };

  return (
    <section className="section page">
      <GoBackButton />
      <div className="container">
        <div className="section-title">
          <h2 className="title"><FormattedMessage id="user_orders_title" /></h2>
        </div>
        <div className="page-content">
          <div className="page-content-inner">
            <div className="table-responsive">
              <table className="table ms-invoice-table">
                <tbody>
                  { userOrders?.map((order, index) => (
                    <tr key={index}>
                      <td className="detail"><span className="order-date">{moment(order.confirmedAt).format('YYYY-MM-DD')}</span></td>
                      <td className="price">
                        <span className="name text-secondary">
                          <FormattedMessage id="user_orders_price" />
                        </span>
                        {' '}
                        <span className="value">
                          {order.totalPriceAmount}
                        </span>
                      </td>
                      <td className="status">
                        <span className="name text-secondary">
                          Төлөв
                        </span>
                        <span className="value">
                          <span className="badge badge-success">
                            <FormattedMessage
                              id="user_orders_status"
                              values={{ selectStatus: order.status }}
                            />
                          </span>
                        </span>
                      </td>
                      <td className="actions">
                        <button onClick={e => modalUserOrder(order.id, e)} className="btn btn-small btn-info">
                          дэлгэрэнгүй
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      { userOrders?.map((order, index) => <UserOrderModal key={index} order={order} />)}
    </section>
  );
};

export default UserOrders;
