import React, { useState, useEffect } from 'react';
import axios from 'axios';
import LocalDataStore from '../dataProvider/LocalDataProvider';

export const HubContext = React.createContext();

const HubProvider = ({ children, hubId }) => {
  const [currentHubId, setCurrentHubId] = useState(hubId);
  const [hub, setHub] = useState(null);

  const setHubId = (id) => {
    setCurrentHubId(id);
    LocalDataStore.setLastVisitedHub(id);
  };

  const fetchHub = (id) => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    return axios.get(`/hubs/${id}`)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.data;
      })
      .then((result) => {
        setHub(result);
      })
      .catch(() => {
        setHub(null);
      });
  };

  useEffect(() => {
    if (hub) return;
    if (!currentHubId) return;

    fetchHub(currentHubId);
  }, [currentHubId]);

  const value = {
    hubId: currentHubId,
    setHubId,
    hub,
    setHub,
    fetchHub,
  };

  return (
    <HubContext.Provider value={value}>
      {children}
    </HubContext.Provider>
  );
};

export default HubProvider;
