import React, { useState } from 'react';
import $ from 'jquery';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Basket from './Basket';
import DeliverySlotContainer from '../containers/DeliverySlotContainer';
import ChooseDelivery from './ChooseDelivery';
import Payment from './Payment';
import OrderSuccessfull from './OrderSuccessfull';
import CandyPayConfirmation from './CandyPayConfirmation';
import HipayCheckout from '../pages/HipayCheckout';
import useAuth from '../hooks/useAuth';
import { setActiveWindow } from '../actions';
import ActivityIndicator from './ActivityIndicator';

const BasketModal = ({ showDeliveryArea }) => {
  const { user, userOrders } = useAuth();
  let confirmedOrder = null;
  if (user) {
    confirmedOrder = userOrders?.find(order => order.status === 'order.confirmed') || null;
  }
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  let activeWindow = useSelector(state => state.activeWindow);

  const handleNavigationLink = (chooseWindow) => {
    setLoading(true);
    switch (chooseWindow) {
      case 'deliverySlot':
        dispatch(setActiveWindow('deliverySlot'));
        break;
      case 'chooseDelivery':
        dispatch(setActiveWindow('chooseDelivery'));
        break;
      case 'payment':
        dispatch(setActiveWindow('payment'));
        break;
      default:
        dispatch(setActiveWindow('basket'));
        break;
    }
  };

  const handleLoading = (state) => {
    setLoading(state);
  };

  const path = location.pathname;
  if (path === '/checkout/success' && confirmedOrder) {
    $('#modal-shopping-cart').modal('show');
    activeWindow = 'orderSuccessfull';
  }

  if (path === '/checkout/candy') {
    $('#modal-shopping-cart').modal('show');
    activeWindow = 'checkoutCandyPay';
  }

  if (path === '/checkout/hipay') {
    $('#modal-shopping-cart').modal('show');
    activeWindow = 'checkoutHipay';
  }

  return (
    <div className="modal modal-shopping-cart fade" id="modal-shopping-cart" role="dialog" aria-hidden="false">
      <div className="modal-dialog d-flex" role="document">
        <div className="modal-content">
          { activeWindow === 'basket'
              && <button type="button" className="close" data-dismiss="modal">&times;</button>
          }
          <div id="ms-basket-modal-content" className="modal-body d-flex flex-column">
            <ActivityIndicator loading={loading} />
            <SwitchWindow
              activeWindow={activeWindow}
              handleNavigationLink={handleNavigationLink}
              confirmedOrder={confirmedOrder}
              handleLoading={handleLoading}
              showDeliveryArea={showDeliveryArea}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

function SwitchWindow({
  activeWindow,
  handleNavigationLink,
  handleLoading,
  showDeliveryArea,
  confirmedOrder,
}) {
  switch (activeWindow) {
    case 'chooseDelivery':
      return (
        <ChooseDelivery
          handleNavigationLink={handleNavigationLink}
          handleLoading={handleLoading}
          showDeliveryArea={showDeliveryArea}
        />
      );
    case 'deliverySlot':
      return (
        <DeliverySlotContainer
          handleNavigationLink={handleNavigationLink}
          handleLoading={handleLoading}
        />
      );
    case 'payment':
      return (
        <Payment
          handleNavigationLink={handleNavigationLink}
          handleLoading={handleLoading}
        />
      );
    case 'orderSuccessfull':
      return (
        <OrderSuccessfull
          handleLoading={handleLoading}
          order={confirmedOrder}
        />
      );
    case 'checkoutCandyPay':
      return (
        <CandyPayConfirmation />
      );
    case 'checkoutHipay':
      return (
        <HipayCheckout />
      );
    default:
      return (
        <Basket handleNavigationLink={handleNavigationLink} handleLoading={handleLoading} />
      );
  }
}

export default BasketModal;
