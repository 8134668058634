import React from 'react';
import { FormattedMessage } from 'react-intl';
import LazyLoad from 'react-lazyload';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import useHub from '../hooks/useHub';
import useBasket from '../hooks/useBasket';

const ProductCard = ({ product, marketId, onSelect }) => {
  const { addBasket, handleMinus, basket } = useBasket();
  const item = basket?.line_items.find(i => i.productUuid === product.uuid);
  const quantity = !!item ? item.quantity : 0;
  const { hubId } = useHub();

  return (
    <Link onClick={e => onSelect(product, e)} to={`/hubs/${hubId}/products/${product.uuid}`}>
      <div className="product-card product-card--large">
        <div className="product-image-imageContainer product-image-imageContainer--medium product-card-image product-image">
          <LazyLoad>
            <img src={product.imagePath} data-product={product.uuid} alt={product.name} />
          </LazyLoad>
        </div>
        <div className="product-card-container">
          <div className="product-card-nameContainer u-mb-1">
            <h5 className="product-card-name">{ product.name }</h5>
          </div>
          <div className="product-card-body">
            <div className="product-card-price-container">
              <div className="product-card-prices">
                { !!product.isOnSale && (
                  <>
                    <span className="price product-card-price compact-product-card-price line-through mr-2 fa-text--info">
                      {product.originalPrice.amount}
                      {' '}
                      ₮
                    </span>
                    <span className="price product-card-price compact-product-card-price fa-text--info on-sale-price">
                      {product.unitPrice.amount}
                      {' '}
                      ₮
                    </span>
                  </>
                )}

                { !product.isOnSale && (
                  <span className="price product-card-price compact-product-card-price fa-text--info">
                    {product.unitPrice.amount}
                    {' '}
                    ₮
                  </span>
                )}
              </div>
              <p className="fa-text fa-text--info fa-text--uiMedium product-card-quantity">
                {product.unitValue}
                {' '}
                <FormattedMessage id={product.unit} />
              </p>
            </div>
            { !product.isStockSufficient
                && (
                  <div className="product-item-card-add-to-basket w-50">
                    <div className="text-right text-danger">Зарагдаж дууссан</div>
                  </div>
                )
            }

            { !!product.isStockSufficient && (
              <div className="product-card-addToBasket">
                <div className="u-flexible-row addToBasket-buttonContainer">
                  { quantity > 0 && (
                    <>
                      <button
                        type="button"
                        onClick={e => handleMinus(item, quantity, e)}
                        className="addToBasket-button"
                        aria-label="Сагснаас бүтээгдэхүүн хасах"
                      >
                        -
                      </button>
                      <div className="fa-text addToBasket-quantity">{quantity}</div>
                    </>
                  )}

                  <button
                    type="button"
                    onClick={e => addBasket(marketId, product.uuid, e)}
                    className="addToBasket-button addToBasket-add"
                    aria-label="Сагсандаа бүтээгдэхүүн нэмэх"
                  >
                    +
                  </button>
                </div>
              </div>
            )}
          </div>
          <div>
            <hr className="separator separator--smallMargins" />
            <div className="product-card-producer">
              <div className="avatar avatar--xsmall avatar--rounded">
                <LazyLoad>
                  <img className="avatar-image" src={product.producer.image} alt={product.producer.name} />
                </LazyLoad>
              </div>
              <p className="fa-text fa-text--uiMedium product-card-producer-name">{product.producer.name}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
};

const mapStateToProps = state => ({
  basket: state.basket,
});

export default connect(mapStateToProps)(ProductCard);
