import React from 'react';
import { Link } from 'react-router-dom';
import ProductList from '../components/ProductList';
import useProducts from '../hooks/useProducts';
import useHub from '../hooks/useHub';

const SuggestProductListContainer = ({ producerUuid }) => {
  const { hubId } = useHub();
  const { products } = useProducts(hubId);

  const filteredProducts = products
    .filter(product => product.producer.uuid === producerUuid)
    .filter(product => !!product.isSellable).slice(0, 3);

  if (products.length === 0) {
    return null;
  }

  return (
    <>
      <div className="mb-5">
        <div className="section-title">
          <h2 className="title mb-4">Таньд санал болгох</h2>
        </div>
        <ProductList products={filteredProducts} />
        <div className="text-right">
          <Link className="btn btn-see-more" name={producerUuid} to={`/hubs/${hubId}/producers/${producerUuid}`}> Бүгдийг үзэх </Link>
        </div>
      </div>
    </>
  );
};

export default SuggestProductListContainer;
