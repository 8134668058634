import React from 'react';
import { FormattedMessage } from 'react-intl';
import { withFormik } from 'formik';
import * as yup from 'yup';
import util from 'util';
import BackButton from './BackButton';
import AutoSuggest from './AutoSuggest';

const ADDITIONAL_KM_CHARGE = process.env.REACT_APP_ADDITIONAL_KM_CHARGE;

const DeliveryMethodForm = (props) => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    handleNavigationLink,
    touched,
    showDeliveryArea,
  } = props;
  const isChosenToBeDelivered = values.deliveryMethod === 'home_delivery' ? 'show' : 'hide';

  return (
    <div id="ms-basket-modal-content" className="modal-body d-flex flex-column">
      <div className="modal-body d-flex flex-column">
        <div className="title">
          <BackButton clickHandler={handleNavigationLink} />
          <h4 className="basket-modal-title">
            <i className="fa fa-calendar" />
            <FormattedMessage id="delivery_title" />
          </h4>
        </div>
        <div className="content">
          <div className="cart-items">
            <form onSubmit={handleSubmit}>
              <div className="cart-item align-items-center">
                <div className="delivery-option d-flex ">
                  <div className="d-flex u-flex1 align-items-center">
                    <label className="item-radio font-weight-bold" htmlFor="pick_up_from_hub">
                      <input
                        type="radio"
                        id="pick_up_from_hub"
                        name="deliveryMethod"
                        onChange={handleChange}
                        value="pick_up_from_hub"
                        checked={values.deliveryMethod === 'pick_up_from_hub'}
                      />
                      {errors.deliveryMethod && <div id="feedback">{errors.deliveryMethod}</div>}
                      <span><FormattedMessage id="pick_up_from_hub" /></span>
                    </label>
                  </div>
                  <div className="d-flex u-flex0 align-items-center">
                    <div className="cart-product-price">
                      <p className="price-value font-weight-bold">0 ₮</p>
                    </div>
                  </div>
                </div>
                <div className="delivery-address">
                  {values?.hub?.venue?.address}
                </div>
              </div>
              <div className="cart-item d-flex align-items-center">
                <div className="d-flex u-flex1 align-items-center">
                  <label className="item-radio font-weight-bold" htmlFor="homeDelivery">
                    <input
                      type="radio"
                      id="homeDelivery"
                      name="deliveryMethod"
                      value="home_delivery"
                      onChange={handleChange}
                      checked={values.deliveryMethod === 'home_delivery'}
                    />
                    <span><FormattedMessage id="home_delivery" /></span>
                  </label>
                </div>
                <div className="d-flex u-flex0 align-items-center">
                  <div className="cart-product-price">
                    <p className="price-value font-weight-bold">{`${values?.deliverySlot?.deliveryFee?.amount} ₮`}</p>
                  </div>
                </div>
              </div>
              <div id="ms-delivery-information" className={`py-3 collapse ${isChosenToBeDelivered}`}>
                <div className={`alert alert-danger threshold-alert collapse ${errors.totalPrice !== undefined ? 'show' : ''}`} role="alert">
                  {errors.totalPrice}
                </div>
                <h5 className="pb-3"><FormattedMessage id="delivery_information" /></h5>
                <div className="form-group col-lg">
                  <label className="control-label" htmlFor="order_delivery_method_phoneNumber">
                    <FormattedMessage id="delivery_form_phone_number" />
                    <span className="label_note"> (хүргэлттэй холбоотой асуудал гарсан үед холбогдоно)</span>
                  </label>
                  <FormattedMessage id="delivery_form_phone_number_placeholder">
                    {msg => (
                      <input
                        type="number"
                        id="order_delivery_method_phoneNumber"
                        name="phoneNumber"
                        value={values.phoneNumber}
                        className="form-control"
                        placeholder={msg}
                        onChange={handleChange}
                      />
                    )}
                  </FormattedMessage>
                  <span className="help-block">
                    <p className="text-danger">
                      {errors.phoneNumber && touched.phoneNumber ? errors.phoneNumber : null}
                    </p>
                  </span>
                </div>
                <div className="form-group col-lg">
                  <ul className="nav nav-custom nav-tabs nav-justified ms-delivery-nav" role="tablist">
                    <li className="nav-item">
                      <a className="nav-link active ms-delivery-nav-link" data-toggle="tab" href="#delivery-address" role="tab" aria-controls="delivery-address" aria-selected="false"><FormattedMessage id="delivery_form_address" /></a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link ms-delivery-nav-link" data-toggle="tab" href="#delivery-address-w3w" role="tab" aria-controls="delivery-address-w3w" aria-selected="false">3 үгт хаяг</a>
                    </li>
                  </ul>
                </div>
                <div className="tab-content">
                  <div className="tab-pane fade show active" id="delivery-address" role="tabpanel">
                    <div className="form-group col-lg">
                      <FormattedMessage id="delivery_form_address_placeholder">
                        {msg => (
                          <textarea
                            id="order_delivery_method_address"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            className="form-control"
                            placeholder={msg}
                          />
                        )
                        }
                      </FormattedMessage>
                      <span className="help-block">
                        <p className="text-danger">
                          {errors.address}
                        </p>
                      </span>
                    </div>
                  </div>
                  <div className="tab-pane fade" id="delivery-address-w3w" role="tabpanel">
                    <div className="form-group col-lg pt-3">
                      <AutoSuggest words={values.what3Words} setFieldValue={setFieldValue} />
                      <input
                        type="hidden"
                        name="what3Words"
                        value={values.what3Words}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group col-lg">
                  <div className="accordion">
                    <p className="mb-0">
                      <button onClick={e => showDeliveryArea(e)} className="btn btn-link btn-lg btn-block" type="button">
                        <FormattedMessage id="delivery_area" />
                      </button>
                    </p>
                  </div>
                </div>
                <div className="alert alert-warning threshold-warning" role="alert">
                  <FormattedMessage
                    id="out_of_delivery_area_notice"
                    values={{
                      additional_km_charge: ADDITIONAL_KM_CHARGE,
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="footer mt-auto">
          <div>
            <button
              type="submit"
              onClick={handleSubmit}
              data-form="delivery-slot"
              className="btn btn-block btn-orange ms-modal-navigation-continue"
            >
              <FormattedMessage id="delivery_slot_continue" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const deliveryValidation = yup.object().shape({
  phoneNumber: yup.number()
    .typeError('Утасны дугаараа оруулна уу')
    .required('Заавал оруулна уу')
    .min(0),
  totalPrice: yup.number()
    .min(process.env.REACT_APP_ORDER_MIN_THRESHOLD, util.format('Хүргэлт хийх худалдан авалтын доод хэмжээ %d ₮. Та нэмж бүтээгдэхүүн сонгоно уу.', process.env.REACT_APP_ORDER_MIN_THRESHOLD)),
  address: yup.string()
    .required('Хүргэлтийн хаягаа оруулна уу')
});

const enhancer = withFormik({
  mapPropsToValues: props => ({
    deliverySlot: props.deliverySlot,
    hub: props.hub,
    deliveryMethod: props.deliveryMethod,
    phoneNumber: props.user.phone !== null
      ? props.user.phone
      : props.deliveryPhoneNumber,
    address: props.user.deliveryAddress !== null
      ? props.user.deliveryAddress.address
      : props.deliveryAddress || '',
    what3Words: props.user.deliveryAddress !== null
      ? props.user.deliveryAddress.what3Words
      : props.what3Words,
    totalPrice: parseInt(props.totalPrice, 10),
  }),

  validationSchema: () => yup.lazy((values) => {
    if (values.deliveryMethod !== 'home_delivery') {
      return yup.object().shape({});
    }

    return deliveryValidation;
  }),

  handleSubmit: (values, { props }) => {
    props.handleDeliveryMethod(values);
  },

  displayName: 'OrderDeliveryMethod',
});

export default enhancer(DeliveryMethodForm);
