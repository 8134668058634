import React from 'react';
import ReactDOM from 'react-dom';
import AriaModal from 'react-aria-modal';
import couponCode from '../images/mongolshim_coupon.png';

export default function CouponModal({ onClose }) {
  return ReactDOM.createPortal(
    <AriaModal
      titleText="Купон Код"
      onExit={onClose}
      focusDialog
      escapeExits
      verticallyCenter
      underlayStyle={{ padding: '10px' }}
      underlayClickExits={false}
      dialogStyle={{ maxWidth: 500 }}
    >
      <div className="modal-content">
        <button
          type="button"
          aria-label="Close"
          onClick={onClose}
          className="closeButton"
        >
          <i className="svgIcon">
            <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" fill="#5C272F">
              <path d="M6 4.65L1.626.278C1.26-.09.65-.093.278.277c-.375.376-.373.977 0 1.35L4.65 6 .278 10.374c-.367.367-.37.975 0 1.348.376.375.977.373 1.35 0L6 7.35l4.374 4.373c.367.367.975.37 1.348 0 .375-.376.373-.977 0-1.35L7.35 6l4.373-4.374c.367-.367.37-.975 0-1.348-.376-.375-.977-.373-1.35 0L6 4.65z" fillRule="evenodd" />
            </svg>
          </i>
        </button>
        <div className="modal-body">
          <div>
            <img className="img-fluid" src={couponCode} alt="Купон" />
          </div>
        </div>
      </div>
    </AriaModal>,
    document.body,
  );
}
