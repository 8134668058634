import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';
import GoBackButton from '../components/GoBackButton';
import SuggestProductListContainer from '../containers/SuggestProductListContainer';
import ProductsSkeleton from '../components/ProductsSkeleton';
import ProductDetail from '../components/ProductDetail';

const ProductDetailPage = () => {
  const selectedProduct = useSelector(state => state.selectedProduct);

  const product = selectedProduct;

  if (product === null) {
    return '';
  }

  const { producer } = product;

  return (
    <Suspense fallback={<ProductsSkeleton />}>
      <div className="page product-page">
        <GoBackButton />
        <ProductDetail product={product} />
        <SuggestProductListContainer producerUuid={producer.uuid} />
      </div>
    </Suspense>
  );
};

export default ProductDetailPage;
