import { SET_ACTIVE_WINDOW } from '../actions/actionTypes';
import { MODAL_WINDOWS } from '../constants';

const initialState = MODAL_WINDOWS.BASKET;

const activeWindow = (state = initialState, action) => {
  switch (action.type) {
    case SET_ACTIVE_WINDOW: {
      return action.payload.activeWindow;
    }
    default: {
      return state;
    }
  }
};

export default activeWindow;
