import { SELECT_PRODUCT } from '../actions/actionTypes';

const selectedProduct = (state = null, action) => {
  switch (action.type) {
    case SELECT_PRODUCT: {
      return action.payload.product;
    }
    default: {
      return state;
    }
  }
};

export default selectedProduct;
