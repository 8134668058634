import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import useHub from '../hooks/useHub';
import useAuth from '../hooks/useAuth';

export const BasketContext = React.createContext();

const BasketProvider = ({ children }) => {
  const [basket, setBasket] = useState(null);
  const [deliverySlot, setDeliverySlot] = useState(null);
  const { token, showLoginForm } = useAuth();
  const { hubId } = useHub();

  const fetchBasket = () => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;
    axios.get(`/hubs/${hubId}/basket`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((result) => {
      setBasket(result.data);
      setDeliverySlot(result.data?.deliverySlot);
    }).catch(() => {
      setBasket(null);
    });
  };

  useEffect(() => {
    if (!token) {
      setBasket(null);
      setDeliverySlot(null);
    }
  }, [token]);

  useEffect(() => {
    if (token && hubId) {
      fetchBasket();
    }
  }, [token, hubId]);

  const addBasket = (marketId, uuid, event) => {
    event.preventDefault();
    event.stopPropagation();

    if (!token) {
      showLoginForm('loginForm');

      return;
    }

    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    const data = {
      product: uuid,
      quantity: 1,
    };

    axios.post(
      `/markets/${marketId}/basket/line_items`,
      qs.stringify(data), {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then((result) => {
      setBasket(result.data);
    }).catch(() => {
      setBasket(null);
    });
  };

  const removeLineItem = (item) => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    axios.delete(`/baskets/${basket.id}/line_items/${item.id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((result) => {
      setBasket(result.data);
    }).catch(() => {
      setBasket(null);
    });
  };

  const handleItemQuantityChange = (item) => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    const data = { quantity: item.quantity };
    axios.patch(`/baskets/${basket.id}/line_items/${item.id}`, qs.stringify(data), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((result) => {
      setBasket(result.data);
    }).catch(() => {
      setBasket(null);
    });
  };

  const handleMinus = (item, quantity, event) => {
    event.preventDefault();
    event.stopPropagation();

    if (quantity <= 1) {
      removeLineItem(item);
      return;
    }

    item.quantity = quantity - 1;

    handleItemQuantityChange(item);
  };

  const changeDeliverySlot = (deliverySlot) => {
    setDeliverySlot(deliverySlot);

    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    axios.patch(`orders/${basket.id}/delivery_slots/${deliverySlot.id}`, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }).then((result) => {
      // @todo replace it when response return a basket
      // setBasket(result.data);
      fetchBasket();
    }).catch(() => {
      // deliverySlot isn't changed
    });
  };

  const value = {
    basket,
    addBasket,
    deliverySlot,
    fetchBasket,
    removeLineItem,
    handleMinus,
    handleItemQuantityChange,
    changeDeliverySlot,
  };

  return (
    <BasketContext.Provider value={value}>
      {children}
    </BasketContext.Provider>
  );
};

export default BasketProvider;
