import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import GoBackButton from '../components/GoBackButton';
import useAuth from '../hooks/useAuth';

const UserProfilePage = () => {
  const { user, onLogout } = useAuth();
  return (
    <div className="page">
      <GoBackButton />
      { !!user && (
        <div className="user-page">
          <div>
            <h2 className="title mb-4">{ user.fullname }</h2>
            <ul className="list mb-3">
              <li>
                <Link className="user-page-link" to="/orders">
                  <i className="svgIcon icon--middle icon--large icon--info">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 438.533 438.533">
                      <path d="M409.133 109.203c-19.608-33.592-46.205-60.19-79.798-79.796C295.735 9.8 259.058 0 219.273 0c-39.78 0-76.47 9.8-110.063 29.407-33.595 19.604-60.192 46.2-79.8 79.796C9.8 142.8 0 179.49 0 219.267c0 39.78 9.804 76.463 29.407 110.062 19.607 33.59 46.204 60.188 79.8 79.797 33.596 19.605 70.282 29.407 110.062 29.407s76.47-9.802 110.064-29.407c33.593-19.602 60.19-46.206 79.795-79.798 19.602-33.597 29.402-70.285 29.402-110.063 0-39.782-9.8-76.472-29.4-110.064zm-55.39 188.005c-13.895 23.79-32.737 42.64-56.528 56.534-23.79 13.894-49.77 20.834-77.945 20.834-28.167 0-54.15-6.94-77.943-20.834C117.537 339.847 98.694 321 84.8 297.208c-13.897-23.79-20.843-49.772-20.843-77.94 0-28.172 6.95-54.153 20.843-77.944 13.89-23.79 32.738-42.637 56.527-56.53 23.79-13.895 49.772-20.84 77.943-20.84 28.173 0 54.154 6.945 77.945 20.84 23.79 13.894 42.634 32.74 56.527 56.53 13.895 23.79 20.838 49.772 20.838 77.943 0 28.17-6.943 54.15-20.838 77.94z" />
                      <path d="M246.68 109.63h-18.274c-2.67 0-4.853.86-6.57 2.57-1.706 1.714-2.565 3.9-2.565 6.566v100.5H155.32c-2.667 0-4.853.855-6.567 2.564-1.71 1.712-2.567 3.9-2.567 6.57v18.272c0 2.666.856 4.856 2.568 6.567 1.716 1.71 3.906 2.565 6.568 2.565h91.367c2.662 0 4.853-.855 6.56-2.566 1.712-1.712 2.574-3.902 2.574-6.568V118.766c0-2.663-.863-4.85-2.574-6.565-1.71-1.71-3.9-2.57-6.566-2.57z" />
                    </svg>
                  </i>
                  {' '}
                  <FormattedMessage id="user.my_orders" />
                </Link>
              </li>
            </ul>
            <button className="btn btn-link p-0" onClick={onLogout}><u><FormattedMessage id="logout" /></u></button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserProfilePage;
