import React from 'react';
import LazyLoad from 'react-lazyload';
import { useNavigate } from 'react-router-dom';
import useHubs from '../hooks/useHubs';
import useHub from '../hooks/useHub';
import ActivityIndicator from './ActivityIndicator';
import backgroundImage from '../images/grocery_background.png';

const HubList = () => {
  const { hubs, isLoading, isError } = useHubs();
  const { setHub } = useHub();
  const navigate = useNavigate();

  if (isLoading) return <ActivityIndicator loading />;
  if (isError) return null;

  const renderImage = (imagePath, name) => (
    <img src={imagePath || backgroundImage} alt={name} />
  );

  const handleOnPress = (e, hub) => {
    e.preventDefault();
    setHub(hub);
    navigate(`/hubs/${hub.uuid}`);
  };

  return (
    <section className="section">
      <div className="container">
        <div className="section-title">
          <h2 className="title mb-4"> Үүрүүд </h2>
        </div>
        <div className="featured-product-list mb-5">
          { hubs?.map(hub => (
            <a href={`/hubs/${hub.uuid}`} tabIndex={0} onClick={e => handleOnPress(e, hub)} onKeyPress={e => handleOnPress(e, hub)}>
              <div className="product-card product-card--large">
                <div className="product-image-imageContainer product-image-imageContainer--medium product-card-image product-image">
                  <LazyLoad>
                    {renderImage(hub.imagePath, hub.name)}
                  </LazyLoad>
                </div>
                <div className="product-card-container">
                  <div className="product-card-nameContainer u-mb-1">
                    <h5 className="product-card-name">{ hub.name }</h5>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HubList;
