import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import useHub from '../hooks/useHub';

const ProductCategory = ({ productCategories }) => {
  const { hubId } = useHub();
  return (
    <>
      <div className="section-title">
        <h2 className="title mb-4"> Бүтээгдэхүүний ангилал </h2>
      </div>
      <div className="row">
        { productCategories.map((productCategory, index) => (
          <div key={index} className="col-12 col-sm-4 col-lg-3 mb-2">
            <Link
              name={productCategory.name}
              className="category-card main"
              to={`/hubs/${hubId}/categories/${productCategory.name}`}
            >
              <h3 name={productCategory.name}>
                <FormattedMessage id={productCategory.name} />
              </h3>
            </Link>
          </div>
        ))}
      </div>
    </>
  );
};

export default ProductCategory;
