import useSWR from 'swr';

const fetcher = (...args) => fetch(...args).then((res) => res.json());

function useProductCategories(hubId) {
  const { data, error, isLoading } = useSWR(
    hubId ? `${process.env.REACT_APP_API_URL}/hubs/${hubId}/product_categories` : null,
    fetcher,
  );

  return {
    categories: data,
    isLoading,
    isErorr: error,
  };
}

export default useProductCategories;
