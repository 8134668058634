import useSWR from 'swr';

const fetcher = (...args) => fetch(...args).then((res) => res.json());

function useProducts(hubId) {
  const { data, error, isLoading } = useSWR(
    hubId ? `${process.env.REACT_APP_API_URL}/hubs/${hubId}/products` : null,
    fetcher,
    {
      suspense: true,
    },
  );

  return {
    products: data?.products,
    marketId: data?.marketId,
    isLoading,
    isErorr: error,
  };
}

export default useProducts;
