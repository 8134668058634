import React, { useEffect } from 'react';
import RestDataProvider from '../dataProvider/RestDataProvider';
import LocalDataProvider from '../dataProvider/LocalDataProvider';
import DeliverySlot from '../components/DeliverySlot';

const DeliverySlotContainer = ({
  handleNavigationLink,
  handleLoading,
}) => {
  const [deliverySlots, setDeliverySlots] = React.useState([]);

  useEffect(() => {
    const dataProvider = RestDataProvider(process.env.REACT_APP_API_URL);

    const lastVisitedHub = LocalDataProvider.getLastVisitedHub();
    const promise = dataProvider('list', `hubs/${lastVisitedHub}/delivery_slots`);
    promise.then((result) => {
      if (result) {
        setDeliverySlots(result);
        handleLoading(false);
      }
    });
  }, []);

  return (
    <DeliverySlot
      handleNavigationLink={handleNavigationLink}
      handleLoading={handleLoading}
      deliverySlots={deliverySlots}
    />
  );
};

export default DeliverySlotContainer;
