import React from 'react';
import { useParams } from 'react-router-dom';
import { Facebook } from 'react-content-loader';
import GoBackButton from './GoBackButton';
import ProductList from './ProductList';
import useProducers from '../hooks/useProducers';
import useProducts from '../hooks/useProducts';
import useHub from '../hooks/useHub';

const ProducerPublicProfile = () => {
  const { uuid } = useParams();
  const { producers, isLoading, isError } = useProducers();
  const { hubId } = useHub();
  const { marketId } = useProducts(hubId);

  const producer = producers?.find(p => p.uuid === uuid);

  const MyFacebookLoader = () => <Facebook />;

  if (isLoading) {
    return (
      <>
        <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-4">
          <MyFacebookLoader />
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-4">
          <MyFacebookLoader />
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-xl-3 mb-4">
          <MyFacebookLoader />
        </div>
      </>
    );
  }

  return (
    <section className="section page">
      <GoBackButton />
      <div className="container">
        <div className="row">
          <div className="sidebar col-lg-3">
            <div className="widget pad-0-15 profile-box">
              <div className="image">
                <img className="mw-100" src={producer.image} alt={producer.name} />
              </div>
              <h1 className="name">{producer.name}</h1>
              <div dangerouslySetInnerHTML={{ __html: producer.description }} />
            </div>
          </div>
          <div className="section-title">
            <h2 className="title mb-4"> Бүтээгдэхүүнүүд </h2>
          </div>
          <ProductList
            products={producer.products}
            marketId={marketId}
          />
        </div>
      </div>
    </section>
  );
};

export default ProducerPublicProfile;
