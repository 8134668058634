import React from 'react';
import useAuth from '../hooks/useAuth';

const LoginForm = () => {
  const { onLogin, loginErrorMessage } = useAuth();

  const onSubmit = (e) => {
    e.preventDefault();

    const username = e.currentTarget.elements._username.value;
    const password = e.currentTarget.elements._password.value;

    onLogin(username, password);
  };

  return (
    <div className="modal-body">
      <div className="auth-form-header text-center">
        <h4>Хэрэглэгч нэвтрэх</h4>
      </div>
      <form onSubmit={onSubmit}>
        <div className="form-group">
          <label htmlFor="username">И-мэйл</label>
          <input className="form-control" type="text" id="username" name="_username" defaultValue="" required="required" />
          <label style={{ color: 'red' }}>{loginErrorMessage}</label>
        </div>
        <div className="form-group">
          <label htmlFor="password">Нууц үг</label>
          <input className="form-control" type="password" id="password" name="_password" required="required" />
        </div>
        <div className="form-group form-row">
          <div className="col">
            <div className="form-check">
              <input type="checkbox" className="form-check-input" id="checkbox1" />
              <label className="form-check-label" htmlFor="checkbox1">Намайг сана</label>
            </div>
          </div>
          <div className="col text-right">
            <a href="/mn/resetting/request"> Нууц үгээ мартсан уу ? </a>
          </div>
        </div>
        <div className="form-group">
          <input className="btn btn-warning btn-orange btn-block btn-auth" type="submit" id="_submit" name="_submit" value="Нэвтрэх" />
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
