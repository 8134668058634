import { UPDATE_BASKET } from '../actions/actionTypes';
import { EMPTY_BASKET } from '../constants';

const initialState = EMPTY_BASKET;

const basket = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_BASKET: {
      return action.payload.basket;
    }
    default: {
      return state;
    }
  }
};

export default basket;
