import {
  SET_ACTIVE_WINDOW,
  SELECT_PRODUCT,
  UPDATE_BASKET,
} from './actionTypes';

export const setActiveWindow = activeWindow => ({ type: SET_ACTIVE_WINDOW, payload: { activeWindow } });

export const selectProduct = product => (
  {
    type: SELECT_PRODUCT,
    payload: { product },
  }
);

export const updateBasket = basket => (
  {
    type: UPDATE_BASKET,
    payload: { basket },
  }
);
