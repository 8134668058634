import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as moment from 'moment';
import 'moment/locale/mn';
import { useNavigate } from 'react-router-dom';
import useHub from '../hooks/useHub';

const OrderSuccessfull = ({ order }) => {
  const navigate = useNavigate();
  const { hub } = useHub();

  if (!order) {
    return '';
  }

  const handleButton = () => {
    navigate('/');
  };

  const orderId = order.id;
  const lineItems = order.line_items;
  const { deliverySlot } = order;
  const deliveryAddress = order.deliveryMethod.name === 'pick_up_from_hub' ? hub?.venue?.address : order.deliveryMethod.address;
  return (
    <>
      <div className="title">
        <h4>
          <i className="fa fa-money" />
          Захиалгын дэлгэрэнгүй
        </h4>
      </div>
      <div className="content order-information pb-0">
        <div className="alert alert-success" role="alert">
          <h4 className="alert-heading">
            <i className="fa fa-check-circle" />
            {' '}
            Захиалга амжилттай баталгаажлаа !
          </h4>
          <p>
            <b>
              Захиалгын дугаар:
              <span className="badge badge-success">{ orderId }</span>
            </b>
          </p>
          <p>
            <h5>
              <FormattedMessage id="payment_distribution_day" />
              :
            </h5>
            <strong>
              {moment(deliverySlot.openAt).format('L')}
              ,
              {moment(deliverySlot.openAt).format('dddd')}
              ,
            </strong>
            &nbsp;
            {moment(deliverySlot.openAt).format('H')}
            {' '}
            -
            {moment(deliverySlot.closeAt).format('H')}
            {' '}
            хооронд
          </p>
          <p>
            <h5>
              <FormattedMessage id="payment_venue_address" />
              :
            </h5>
            { deliveryAddress }
          </p>

        </div>
        <table className="table table-sm">
          <thead>
            <tr>
              <th scope="col">Бүтээгдэхүүний нэр</th>
              <th scope="col">Тоо</th>
              <th scope="col">Үнэ</th>
            </tr>
          </thead>
          <tbody>
            { lineItems.map(lineItem => (
              <tr>
                <td>{lineItem.productName}</td>
                <td>{lineItem.quantity}</td>
                <td>{lineItem.displayTotal}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="footer mt-auto">
        <div>
          <button onClick={handleButton} className="btn btn-block btn-success" data-dismiss="modal">Нүүр хуудас руу очих</button>
        </div>
      </div>
    </>
  );
};

export default OrderSuccessfull;
