import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import $ from 'jquery';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import * as moment from 'moment';
import 'moment/locale/mn';
import BackButton from './BackButton';
import useBasket from '../hooks/useBasket';

const DeliverySlot = ({
  deliverySlots,
  handleNavigationLink,
  handleLoading,
}) => {
  const [tabIndex, setTabIndex] = useState(0);
  const { deliverySlot, changeDeliverySlot } = useBasket();

  const handleContinue = (e) => {
    e.preventDefault();

    handleLoading(true);

    if (!deliverySlot) {
      handleLoading(false);
      $('.select-deliveryslot-alert').text('Захиалга хүлээн авах өдрөө сонгоно уу').show().delay(5000)
        .fadeOut(400);

      return;
    }

    handleNavigationLink('chooseDelivery');
  }

  const groupDeliverySlotsByDate = (deliverySlots) => {
    const deliveryDays = [];
    const map = new Map();
    for (const deliverySlot of deliverySlots) {
      const deliveryDay = moment(deliverySlot.openAt).format('YYYY-MM-DD');
      if (!map.has(deliveryDay)) {
        map.set(deliveryDay, true);
        deliveryDays[deliveryDay] = {
          deliverySlot,
          timeSlots: deliverySlots.filter(deliverySlot => deliveryDay === moment(deliverySlot.openAt).format('YYYY-MM-DD')),
        };
      }
    }

    return deliveryDays;
  };

  const renderDeliverySlotsInTabs = () => {
    const deliveryDays = groupDeliverySlotsByDate(deliverySlots);
    const tabs = [];
    const tabPanels = [];

    const selectedDeliverySlotId = deliverySlot?.id;
    Object.keys(deliveryDays).forEach((date, index) => {
      const { deliverySlot, timeSlots } = deliveryDays[date];

      if (tabIndex === null && selectedDeliverySlotId === deliverySlot.id) {
        setTabIndex(index);
      }

      tabs.push(
        <Tab className="delivery-slot-date-item">
          <span className="item-weekday">{moment(deliverySlot.openAt).format('ddd').toUpperCase()}</span>
          <span className="item-month">{moment(deliverySlot.openAt).format('YYYY-MM-DD')}</span>
        </Tab>,
      );

      const timeSlotsTemplate = timeSlots.map(deliverySlot => (
        <div className="delivery-options">
          <div className="delivery-options-item">
            <label className="item-radio" htmlFor={deliverySlot.id}>
              <input
                id={deliverySlot.id}
                value={deliverySlot.id}
                name="delivery_slot"
                onChange={() => changeDeliverySlot(deliverySlot)}
                checked={selectedDeliverySlotId === deliverySlot.id}
                className="delivery-options-radio"
                type="radio"
                disabled={!deliverySlot.available}
              />
              <div className="delivery-options-radio-container">
                <div className="delivery-options-radio-container-item">
                  <FormattedMessage
                    id="delivery_slot_form_two"
                    values={{ startTime: moment(deliverySlot.openAt).format('HH:mm'), endTime: moment(deliverySlot.closeAt).format('HH:mm') }}
                  />
                </div>
                <div className="delivery-fee-container">
                  <div className="item">
                    <div className="delivery-fee-value" />
                  </div>
                </div>
                <div className="select-delivery-container">
                  { !deliverySlot.available && (
                    <button
                      type="button"
                      className="select-delivery-container-button disabled"
                      disabled
                    >
                      Захиалга дүүрсэн
                    </button>
                  )}
                  { !!deliverySlot.available && (
                    <button
                      type="button"
                      className="select-delivery-container-button"
                      onClick={() => changeDeliverySlot(deliverySlot)}
                    >
                      Сонгох
                    </button>
                  )}
                </div>
              </div>
            </label>
          </div>
        </div>
      ));

      tabPanels.push(<TabPanel>{timeSlotsTemplate}</TabPanel>);
    });

    return (
      <Tabs
        selectedTabClassName="selected"
        selectedIndex={tabIndex}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabList className="delivery-slot-date-list">{tabs}</TabList>
        {tabPanels}
      </Tabs>
    );
  };

  return (
    <div className="modal-body d-flex flex-column">
      <div className="title">
        <BackButton clickHandler={() => handleNavigationLink('basket')} />
        <h4 className="basket-modal-title">
          <i className="fa fa-calendar" />
          <FormattedMessage id="select_delivery_slot" />
        </h4>
      </div>
      <div className="delivery-slot-content">
        <div className="delivery-slot-item">
          {renderDeliverySlotsInTabs()}
        </div>
      </div>
      <div className="footer mt-auto">
        <div className="alert alert-warning select-deliveryslot-alert collapse" role="alert" />
        <div>
          <button
            onClick={handleContinue}
            data-form="delivery-slot"
            className="btn btn-block btn-orange ms-modal-navigation-continue"
          >
            <FormattedMessage id="delivery_slot_continue" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeliverySlot;
