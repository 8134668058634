import useSWR from 'swr';

const fetcher = (...args) => fetch(...args).then(res => res.json());
function useHubs() {
  const { data, error, isLoading } = useSWR(
    `${process.env.REACT_APP_API_URL}/hubs_new`,
    fetcher,
  );

  return {
    hubs: data,
    isLoading,
    isErorr: error,
  };
}

export default useHubs;
