import React from 'react';
import { useNavigate } from 'react-router-dom';

const GoBackButton = () => {
  const navigate = useNavigate();

  return (
    <div className="back-to-container">
      <a onClick={() => navigate(-1)} className="btn back-to" href="#">
        Буцах
      </a>
    </div>
  );
}

export default GoBackButton;
