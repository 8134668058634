import React from 'react';
import { FormattedMessage } from 'react-intl';
import $ from 'jquery';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import MobileNavigation from './MobileNavigation';
import logo from '../images/logo.png';
import LocalDataProvider from '../dataProvider/LocalDataProvider';
import useHubs from '../hooks/useHubs';
import useProductCategories from '../hooks/useProductCategories';
import useHub from '../hooks/useHub';
import useAuth from '../hooks/useAuth';

const Header = ({
  basket, showHowItWorksInfo, showDeliveryArea,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { toastText } = useAuth();

  const { hubId } = useHub();
  const { hubs, isLoading } = useHubs();
  const { categories } = useProductCategories(hubId);

  const selectHub = (uuid) => {
    $('.site_overlay').css('display', 'none');
    $('body').removeClass('site_overlay_visible mobile_nav_visible');

    navigate(`/hubs/${uuid}`);
  };

  const afterApplyingFilter = (event) => {
    event.preventDefault();

    $('.site_overlay').css('display', 'none');
    $('body').removeClass('site_overlay_visible mobile_nav_visible');

    if (!event.target.name) {
      navigate('/');

      return;
    }

    navigate(`/hubs/${hubId}/categories/${event.target.name}`);
  };

  let filter = 'all';
  if (!!location.pathname.match(/categories\/([a-z_]+)/)) {
    filter = location.pathname.match(/categories\/([a-z_]+)/)[1];
  }

  const countItems = basket ? basket.line_items.length : 0;
  const lastVisitedHub = LocalDataProvider.getLastVisitedHub();

  return (
    <React.Fragment>
      <div className="push-nav">
        <div className="push-nav-wrap">
          <div className="side_menu_header">
            <div className="side_menu_header_inner d-flex justify-content-between align-items-center">
              <div>
                <h5>Монгол Шим</h5>
              </div>
              <div>
                <a href="#" className="hide_mobile_menu"><i className="icon-close" /></a>
              </div>
            </div>
          </div>
          <div className="side_menu_block">
            <nav className="push_nav_menu">
              <ul className="">
                { !isLoading && hubs?.map(hub => (
                  <li>
                    <a className={hub.uuid === lastVisitedHub ? 'active' : ''} onClick={() => selectHub(hub.uuid)} href="#">{hub.name}</a>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
          <div className="side_menu_block">
            <nav className="push_nav_menu">
              <ul className="">
                <li><a onClick={e => showHowItWorksInfo(e)} href="#">Хэрхэн ажилладаг вэ?</a></li>
                <li><a onClick={e => showDeliveryArea(e)} href="#">Хүргэлтийн бүс</a></li>
              </ul>
            </nav>
          </div>
          <div className="side_menu_block">
            <h5>Ангилал</h5>
            <nav className="push_nav_menu">
              <ul className="">
                {/* <li><Link onClick={e => afterApplyingFilter(e)} to="/">Бүгд</Link></li> */}
                { categories?.map(category => (
                  <li
                    key={category.name}
                  >
                    <Link
                      name={category.name}
                      onClick={e => afterApplyingFilter(e)}
                      to={`/categories/${category.name}`}
                    >
                      <FormattedMessage id={category.name} />
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
      <header id="header" className="site-header">
        <MobileNavigation countItems={countItems} />
        <div className="site-header-inner d-flex">
          <div className="header-left d-flex">
            <a href="/" className="mobile-menu show_mobile_menu d-xl-none">
              <div className="mobile-menu-button">
                <span />
              </div>
            </a>
            <div className="site-logo">
              <Link to="/"><img src={logo} alt="Монгол Шим" /></Link>
            </div>
            <div className="site-search d-none">
              <form>
                <div className="search-input">
                  <input type="text" className="form-control" placeholder="Хайлт..." />
                </div>
              </form>
            </div>
          </div>
        </div>
      </header>
      <div className="nav-scroller bg-white box-shadow">
        <nav className="nav nav-underline">
          { categories?.map((category, index) => (
            <Link
              key={index}
              to={`/hubs/${hubId}/categories/${category.name}`}
              className={`nav-link ${category.name === filter && 'active'}`}
              name={category.name}
            >
              <FormattedMessage id={category.name} />
            </Link>
          ))}
        </nav>
      </div>
      <div className="fixed-bottom">
        <div className="toast ml-auto login-toast" role="status" aria-live="polite" data-delay="2000">
          <div className="toast-body">
            {toastText}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Header;
