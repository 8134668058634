import React, { useState, useRef } from 'react';
import useBasket from '../hooks/useBasket';

const LineItem = ({ item }) => {
  const {
    productName,
    unit,
    quantity,
    imagePath,
    displayTotal,
  } = item;

  const { handleItemQuantityChange, removeLineItem } = useBasket();

  const units = (unit).split(' ');

  const [quantityValue, setQuantityValue] = useState(quantity);
  const textInput = useRef();

  const handleMinus = (event) => {
    event.preventDefault();

    const itemCount = parseInt(textInput.current.value, 10);
    if (itemCount <= 1) {
      return;
    }
    setQuantityValue(itemCount - 1);

    item.quantity = itemCount - 1;

    handleItemQuantityChange(item);
  };

  const handlePlus = (event) => {
    event.preventDefault();

    const itemCount = parseInt(textInput.current.value, 10);
    setQuantityValue(itemCount + 1);

    item.quantity = itemCount + 1;

    if (textInput) handleItemQuantityChange(item);
  };

  const handleRemove = (event) => {
    event.preventDefault();

    removeLineItem(item);
  };

  return (
    <div className="cart-item d-flex align-items-sm-center flex-column flex-sm-row hide">
      <div className="d-flex u-flex1 align-items-center">
        <div className="image u-flex0">
          <img src={imagePath} alt={productName} />
        </div>
        <div className="info u-flex1">
          <h5>{ productName }</h5>
          <p>
            {units[0]}
            {' '}
            {units[1]}
          </p>
        </div>
      </div>
      <div className="cart-item-right d-flex u-flex0 align-items-center">
        <div className="cart-cnt">
          <div className="cart-cnt-btn-group d-flex">
            <a onClick={handleMinus} className="button-qnt minus">-</a>
            <input ref={textInput} type="text" disabled value={quantityValue} />
            <a onClick={handlePlus} className="button-qnt plus">+</a>
          </div>
        </div>
        <div className="cart-product-price">
          <span className="price-value">{ displayTotal }</span>
        </div>
        <div className="cart-item-delete">
          <button onClick={handleRemove} type="button" className="button-delete-cart-item">×</button>
        </div>
      </div>
    </div>
  );
};

export default LineItem;
