import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import util from 'util';
import RestDataProvider from '../dataProvider/RestDataProvider';
import { selectProduct } from '../actions';
import useAuth from '../hooks/useAuth';
import useBasket from '../hooks/useBasket';
import useHub from '../hooks/useHub';
import useProducts from '../hooks/useProducts';

const ProductDetail = ({ product }) => {
  const { token, showLoginForm, user } = useAuth();
  const { addBasket } = useBasket();
  const dispatch = useDispatch();
  const { hubId } = useHub();
  const { marketId } = useProducts(hubId);

  const handleFavorite = (event) => {
    const dataProvider = RestDataProvider(process.env.REACT_APP_API_URL);
    const url = util.format('products/%s/reaction', product.uuid);

    if (!token) {
      showLoginForm('loginForm');

      return;
    }

    dataProvider('create', url, { data: {} })
      .then((result) => {
        dispatch(selectProduct(result));
      });
  };

  const renderFavorite = () => {
    const favorited = !!product.reactions && product.reactions.users
      .find(reactedUser => reactedUser.userId === parseInt(user?.id, 10) && reactedUser.favorite === 'reaction.favorite');
    const favoriteCount = !!product.reactions ? product.reactions.favoriteCount : 0;
    return (
      <div className="favorite-main">
        <div className="favorite-container">
          <div
            role="button"
            onClick={handleFavorite}
            className={`favorite-button ${!!favorited ? 'favorite' : 'favorite-default'}`}
            radius="44"
          >
            <svg
              className="favorite-icon"
              width="20"
              height="20"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="-1 -2 22 18"
            >
              <path
                className={`PathIcon-sc-1qr0hpz-1 ${!!favorited ? 'favorite' : 'unfavorite'}`}
                width="20"
                height="20"
                d="M12.622 0.306809C11.5002 0.984 10.5596 2.04794 10 3.34375C9.44044 2.04794 8.49976 0.984 7.37803 0.306809C5.7647 -0.667162 3.74712 -0.852568 1.97259 0.269478L1.97227 0.269685C0.209286 1.38634 -0.59136 3.24927 -0.491708 5.19972C-0.39246 7.14228 0.594596 9.17257 2.39058 10.7136C4.7364 12.7312 7.17657 14.6355 9.71344 16.4097C9.88552 16.5301 10.1145 16.5301 10.2866 16.4097C12.8218 14.6366 15.2657 12.7294 17.6098 10.7133C19.4055 9.17232 20.3925 7.14216 20.4917 5.19972C20.5914 3.24927 19.7907 1.38634 18.0277 0.269685L18.0274 0.269478C16.2529 -0.852568 14.2353 -0.667162 12.622 0.306809Z"
                id="m39419295006"
                viewBox="-1 -2 22 18"
                fill="white"
              />
            </svg>
          </div>
          <div><p className="favorite-count">{ favoriteCount }</p></div>
        </div>
      </div>
    );
  };

  const renderPrice = (product) => {
    if (product.isOnSale) {
      return (
        <>
          <span className="line-through mr-2" itemProp="priceCurrency" content="MNT">
            { `${product.originalPrice.amount} ₮` }
          </span>
          <span className=" on-sale-price" itemProp="priceCurrency" content="MNT">
            { `${product.unitPrice.amount} ₮` }
          </span>
        </>
      );
    }

    return (
      <span itemProp="priceCurrency" content="MNT">
        { `${product.unitPrice.amount} ₮` }
      </span>
    );
  };

  const { producer } = product;

  return (
    <>
      <div className="product-page-product mb-5">
        <div className="product-page-product-summary row no-gutters mb-4">
          <div className="col-sm-12 col-md-7">
            <img width="100%" src={product.modalImage} alt={`${product.name} - ${producer.name} - Монгол Шим`} />
          </div>
          <div className="col-sm-12 col-md-5 d-flex flex-column justify-content-between p-3" itemScope="" itemType="http://schema.org/Product">
            <div className="product-info-container">
              <div className="product-name-container">
                <h1 className="product-page-product-name" itemProp="name">
                  {' '}
                  { product.name }
                </h1>
                <div className="producer" itemScope="" itemType="http://schema.org/Brand" itemProp="brand">
                  <div className="image-wrapper">
                    <img src={producer.image} alt={`${producer.name} - Монгол Шим`} />
                  </div>
                  <div className="producer-name">
                    {' '}
                    { producer.name }
                    <div className="location" />
                  </div>
                </div>
              </div>
              { renderFavorite() }
            </div>
            <div>
              <div className="d-flex mb-2" itemProp="offers" itemScope="" itemType="http://schema.org/Offer">
                <div className="product-page-product-unit-quantity">
                  {product.unitValue}
                  {' '}
                  <FormattedMessage id={product.unit} />
                </div>
                <div className="product-page-product-unit-price" itemProp="price" content={product.unitPrice.amount}>
                  { renderPrice(product) }
                  <div className="product-page-product-price-per-quantity" />
                </div>
              </div>
              { !!product.isSellable && product.isStockSufficient && (
                <div className="add-to-basket">
                  <button
                    type="button"
                    onClick={e => addBasket(marketId, product.uuid, e)}
                    className="btn btn-green add-first-item"
                  >
                    Сагсанд нэмэх
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-sm-12 col-md-7">
            <div className="mb-3" itemProp="description">
              <p className="text" dangerouslySetInnerHTML={{ __html: product.description }} />
            </div>
          </div>
        </div>
      </div>
      <div className="product-page-producer">
        <h3>Үйлдвэрлэгч</h3>
        <div className="row mb-4">
          <div className="col-xs-12 col-sm-6 col-md-7 col-lg-6">
            <h2 className="product-page-producer-name">{ producer.name }</h2>
            <div className="mb-2">{ producer.address }</div>
            <p className="text" dangerouslySetInnerHTML={{ __html: producer.description }} />
          </div>
          <div className="product-page-producerPhoto col-xs-12 col-sm-6 col-md-5 col-lg-6">
            <img className="photo" width="100%" src={producer.image} alt={`${producer.name} - Монгол Шим`} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductDetail;
