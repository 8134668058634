import React, { useEffect, Suspense } from 'react';
import {
  useParams,
  Outlet,
  useMatch,
} from 'react-router-dom';
import ProductCatalog from '../components/ProductCatalog';
import useHub from '../hooks/useHub';
import ProductsSkeleton from '../components/ProductsSkeleton';

const HubDetail = () => {
  const { id } = useParams();
  const { setHubId, fetchHub } = useHub();

  useEffect(() => {
    setHubId(id);
  }, [id, setHubId]);

  /*
   * Fetch hub data if it's not available
   */
  useEffect(() => {
    fetchHub(id);
  }, [id]);

  const isHubDetailPageActive = useMatch('/hubs/:id');

  return (
    <React.Fragment>
      { Boolean(isHubDetailPageActive) && (
        <Suspense fallback={<ProductsSkeleton />}>
          <ProductCatalog />
        </Suspense>
      )}
      <Outlet />
    </React.Fragment>
  );
};

export default HubDetail;
