import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import ProductList from './ProductList';
import ProductCategory from './ProductCategory';
import FeaturedProductList from './FeaturedProductList';
import OnSaleProductListContainer from '../containers/OnSaleProductListContainer';
import ProducerList from './ProducerList';
import ConfirmedOrderBanner from './ConfirmedOrderBanner';
import useHub from '../hooks/useHub';
import useProducts from '../hooks/useProducts';
import useProductCategories from '../hooks/useProductCategories';
import Banner from './Banner';

const ProductCatalog = () => {
  const { hubId } = useHub();
  const { categories } = useProductCategories(hubId);
  const { products, marketId } = useProducts(hubId);

  return (
    <main className="main-content">
      <section id="section-buy-now" className="section section-buy-now pb-0">
        <div className="container">
          <ConfirmedOrderBanner />
          <Banner />
          <FeaturedProductList
            products={products}
            marketId={marketId}
          />
          <OnSaleProductListContainer
            products={products}
            marketId={marketId}
          />
          { categories?.filter(category => category.name !== 'bundles').map(category => (
            <div key={category.id} className="mb-5">
              <div className="section-title">
                <h2 className="title mb-4">
                  {' '}
                  <FormattedMessage id={category.name} />
                </h2>
              </div>
              <ProductList
                products={products?.filter(product => product.type === category.name)}
                marketId={marketId}
              />
            </div>
          ))}
        </div>
      </section>
      <section className="section">
        <div className="container">
          { categories?.length > 0
              && <ProductCategory productCategories={categories} />
          }
        </div>
      </section>
    </main>
  );
};

export default ProductCatalog;
