import $ from 'jquery';

$(() => {
  const siteOverlay = $('<div class="site_overlay"></div>');

  /** WINDOW SCROLL * */
  $(window).scroll(() => {
    const eTop = $('#header')?.offset().top;
    if (eTop - $(window).scrollTop() <= 0) {
      $('body').addClass('header-fixed');
    } else {
      $('body').removeClass('header-fixed');
    }
  });

  /* SHOW MOBILE NAV */
  $('.show_mobile_menu').on('click', () => {
    $('html').addClass('no-scroll');
    $('body').addClass('site_overlay_visible mobile_nav_visible');

    siteOverlay.appendTo('body').fadeIn(200);

    return false;
  });

  /* HIDE MOBILE NAV */
  $('.hide_mobile_menu').on('click', () => {
    $('html').removeClass('no-scroll');
    $('body').removeClass('mobile_nav_visible site_overlay_visible');

    $('.site_overlay').fadeOut(300);

    return false;
  });

  /* OVERLAY CLICK */
  $(document).on('click', '.site_overlay', () => {
    $('html').removeClass('no-scroll');
    $('body').removeClass('site_overlay_visible site_search_visible mobile_nav_visible');
    $('.site_overlay').fadeOut(300);
    return false;
  });
});
