import React, { CSSProperties } from 'react';
import { ClipLoader } from 'react-spinners';
import PropTypes from 'prop-types';

const override: CSSProperties = {
  cursor: 'pointer',
  display: 'inline-block',
  margin: '0 auto',
  left: '50%',
  pointerEvents: 'none',
  position: 'absolute',
  zIndex: 999,
  top: '50%',
  verticalAlign: 'middle',
};

const ActivityIndicator = ({ loading }) => (
  <ClipLoader
    cssOverride={override}
    size={48}
    color="#ffa500"
    aria-label="Loading Spinner"
    loading={loading}
  />
);

ActivityIndicator.propTypes = {
  loading: PropTypes.bool.isRequired,
};

export default ActivityIndicator;
