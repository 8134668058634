import React, { useEffect, useState } from 'react';
import { IntlProvider, addLocaleData } from 'react-intl';
import mnLocaleData from 'react-intl/locale-data/mn';
import $ from 'jquery';
import Cookies from 'js-cookie';
import ReactGA from 'react-ga';
import {
  Route, Routes, useSearchParams,
} from 'react-router-dom';
import Header from './components/Header';
import HubList from './components/HubList';
import Footer from './components/Footer';
import DeliveryAreaModal from './components/DeliveryAreaModal';
import HowItWorksModal from './components/HowItWorksModal';
import LoginModal from './components/LoginModal';
import UserOrders from './components/user/UserOrders';
import UserProfilePage from './pages/UserProfilePage';
import 'intl';
import translations from './components/translations/messages.json';
import CouponModal from './components/CouponModal';
import HubDetail from './pages/HubDetail';
import ProducerPublicProfile from './components/ProducerPublicProfile';
import FilteredProducts from './components/FilteredProducts';
import ProductDetailPage from './pages/ProductDetailPage';
import useAuth from './hooks/useAuth';
import BasketModal from './components/BasketModal';

ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

addLocaleData([...mnLocaleData]);

const App = () => {
  const [locale] = useState('mn');
  const [messages] = useState(translations.mn);
  const [deliveryAreaModalActive, setDeliveryAreaModalActive] = useState(false);
  const [howItWorksModalActive, setHowItWorksModalActive] = useState(false);
  const [couponModalActive, setCouponModalActive] = useState(false);

  const [searchParams] = useSearchParams();
  const { handleAuthorizationCode } = useAuth();
  useEffect(() => {
    if (searchParams.get('code')) {
      if (!Cookies.get('accessToken')) {
        handleAuthorizationCode(searchParams.get('code'));
      }
    }
  }, []);

  const showDeliveryArea = (event) => {
    event.preventDefault();

    $('.site_overlay').css('display', 'none');
    $('body').removeClass('site_overlay_visible mobile_nav_visible');

    setDeliveryAreaModalActive(true);
  };

  const showHowItWorksInfo = (event) => {
    event.preventDefault();

    $('.site_overlay').css('display', 'none');
    $('body').removeClass('site_overlay_visible mobile_nav_visible');

    setHowItWorksModalActive(true);
  };

  return (
    <IntlProvider key={locale} locale={locale} messages={messages} textComponent={React.Fragment}>
      <React.Fragment>
        <React.Suspense fallback={<div>Loading...</div>}>
          {deliveryAreaModalActive && (
            <DeliveryAreaModal
              onClose={() => setDeliveryAreaModalActive(false)}
            />
          )}
          {couponModalActive && (
            <CouponModal onClose={() => setCouponModalActive(false)} />
          )}
        </React.Suspense>
        <React.Suspense fallback={<div>Loading...</div>}>
          {howItWorksModalActive && (
            <HowItWorksModal onClose={() => setHowItWorksModalActive(false)} />
          )}
        </React.Suspense>
        <BasketModal showDeliveryArea={showDeliveryArea} />
        <Header
          showDeliveryArea={showDeliveryArea}
          showHowItWorksInfo={showHowItWorksInfo}
        />
        <Routes>
          <Route index element={<HubList />} />
          <Route
            path="/hubs/:id"
            end
            element={(
              <HubDetail />
            )}
          >
            <Route
              path="categories/:slug"
              element={(
                <FilteredProducts />
              )}
            />
            <Route
              path="products/:uuid"
              end
              element={(
                <ProductDetailPage />
              )}
            />
            <Route
              path="producers/:uuid"
              element={(
                <ProducerPublicProfile />
              )}
            />
          </Route>
          <Route path="/orders" element={<UserOrders />} />
          <Route
            path="/profile"
            element={<UserProfilePage />}
          />
        </Routes>
        <Footer />
        <LoginModal />
      </React.Fragment>
    </IntlProvider>
  );
};

export default App;
