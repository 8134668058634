import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ProductCard from './ProductCard';
import { selectProduct } from '../actions';
import useHub from '../hooks/useHub';

const FeaturedProductList = ({ products, marketId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hubId } = useHub();

  const onSelect = (selectedProduct, event) => {
    event.preventDefault();

    dispatch(selectProduct(selectedProduct));
    navigate(`/hubs/${hubId}/products/${selectedProduct.uuid}`);
  };

  const filteredProducts = products?.filter(product => product.featured === true);

  if (!filteredProducts || filteredProducts?.length === 0) {
    return null;
  }

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <div className="section-title">
        <h2 className="title mb-4"> Онцлох </h2>
      </div>
      <div className="featured-product-list mb-5">
        { filteredProducts?.map((product, index) => (
          <ProductCard
            key={index}
            product={product}
            marketId={marketId}
            onSelect={onSelect}
          />
        ))}
      </div>
    </React.Suspense>
  );
};

export default FeaturedProductList;
