const setLastVisitedHub = (hubId) => {
  localStorage.setItem('LAST_VISITED_HUB', hubId);
};

const getLastVisitedHub = () => localStorage.getItem('LAST_VISITED_HUB');

export default {
  setLastVisitedHub,
  getLastVisitedHub,
};
