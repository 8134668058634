import React, { useState } from 'react';
import useHub from '../hooks/useHub';

const FaqModal = React.lazy(() => import('./FaqModal.js'));

const Footer = () => {
  const [faqOpened, setFaqOpened] = useState(false);
  const { hub } = useHub();

  if (!hub) return null;

  const { venue, host } = hub;

  const onFaqOpened = (event) => {
    event.preventDefault();

    setFaqOpened(true);
  };

  return (
    <React.Fragment>
      <footer id="footer" className="site-footer">
        <div className="footer-main">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-3">
                <div className="footer-block">
                  <h4>Манай хаяг</h4>
                  <p>{venue?.address}</p>
                </div>

              </div>

              <div className="col-lg-2 offset-lg-3 col-md-2">
                <div className="footer-block" />

              </div>

              <div className="col-lg-2 col-md-2">
                <div className="footer-block">
                  <h4>Сошиал хаяг</h4>
                  <ul>
                    <li>
                      <a href="https://facebook.com/mongolshim">
                        <i className="fa fa-facebook" />
                        {' '}
                        Фэйсбүүk
                      </a>
                    </li>
                    <li>
                      <a href="https://twitter.com/mongolshim">
                        <i className="fa fa-twitter" />
                        {' '}
                        Твиттер
                      </a>
                    </li>
                    <li>
                      <a href="http://blog.mongolshim.mn">
                        <i className="fa fa-newspaper-o" />
                        {' '}
                        Блог
                      </a>
                    </li>
                  </ul>
                </div>

              </div>

              <div className="col-lg-2 col-md-3">
                <div className="footer-block">
                  <h4>Манай хаяг</h4>
                  <ul>
                    <li>
                      <a href="mailto:hello@mongolshim.mn">
                        <i className="fa fa-envelope" />
                        {' '}
                        hello@mongolshim.mn
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-phone-square" />
                        {host.phone}
                      </a>
                    </li>
                    <li><a onClick={onFaqOpened} href="#"><i className="fa fa-question"> Түгээмэл асуултууд</i></a></li>
                  </ul>
                </div>

                <React.Suspense fallback={<div>Loading...</div>}>
                  {faqOpened && (
                    <FaqModal onClose={() => setFaqOpened(false)} />
                  )}
                </React.Suspense>

              </div>
            </div>
          </div>
        </div>

        <div className="footer-copyright">

          <a href="#" id="totop"><i className="fa fa-angle-up" /></a>

          <div className="container">
            {`© ${new Date().getFullYear()} Монгол Шим. All Rights Reserved.`}
          </div>
        </div>

      </footer>

      <div className="target" />
    </React.Fragment>
  );
};

export default Footer;
