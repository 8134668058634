import React from 'react';
import $ from 'jquery';
import { withRouter } from '../lib/withRouter';

class EmptyBasket extends React.Component {
  constructor(props) {
    super(props);

    this.handleOrderLink = this.handleOrderLink.bind(this);
  }

  handleOrderLink(event) {
    $('#modal-shopping-cart').modal('hide');

    this.props.history.push('/orders');
  }

  chooseProducts(event) {
    event.preventDefault();

    $('#modal-shopping-cart').modal('hide');
    window.location.href = '#section-buy-now';
  }

  render() {
    return (
      <div className="modal-body d-flex flex-column">
        <div className="title">
          {
            !!this.props.confirmedOrder
            && (
              <>
                <h4 style={{ lineHeight: '50px', height: '44px' }}>
                  <i className="fa fa-shopping-basket" />
Миний сагс
                </h4>
                <button className="btn btn-link p-0" style={{ lineHeight: 1 }} onClick={this.handleOrderLink}>Миний захиалгууд</button>
              </>
            )
          }
          {
            !this.props.confirmedOrder && (
            <h4>
              <i className="fa fa-shopping-basket" />
Миний сагс
            </h4>
            )
          }
        </div>
        <div className="content">
          <div className="basket-panel-top">
            <p className="text-center">Таны сагс хоосон байна</p>
            <button className="btn btn-warning btn-orange btn-block btn-auth" onClick={this.chooseProducts}>Бүтээгдэхүүн сонгох</button>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(EmptyBasket);
