import React from 'react';
import ReactDOM from 'react-dom';
import AriaModal from 'react-aria-modal';
import LazyLoad from 'react-lazyload';
import { compose, withProps } from 'recompose';
import {
  withScriptjs, withGoogleMap, GoogleMap, Polygon,
} from 'react-google-maps';
import util from 'util';

export default function DeliveryAreaModal({ onClose }) {
  const coords = [
    { lat: 47.92397, lng: 106.97445 },
    { lat: 47.91633, lng: 106.97321 },
    { lat: 47.91243, lng: 106.97731 },
    { lat: 47.90849, lng: 106.97111 },
    { lat: 47.90556, lng: 106.9674 },
    { lat: 47.90324, lng: 106.9438 },
    { lat: 47.90023, lng: 106.93079 },
    { lat: 47.89045, lng: 106.93817 },
    { lat: 47.88515, lng: 106.9404 },
    { lat: 47.88652, lng: 106.91891 },
    { lat: 47.87686, lng: 106.91445 },
    { lat: 47.87707, lng: 106.91165 },
    { lat: 47.87698, lng: 106.90815 },
    { lat: 47.88462, lng: 106.8976 },
    { lat: 47.88527, lng: 106.88839 },
    { lat: 47.88631, lng: 106.88341 },
    { lat: 47.88574, lng: 106.87818 },
    { lat: 47.88642, lng: 106.87654 },
    { lat: 47.88596, lng: 106.87346 },
    { lat: 47.88619, lng: 106.87002 },
    { lat: 47.8863, lng: 106.86734 },
    { lat: 47.88575, lng: 106.8636 },
    { lat: 47.88958, lng: 106.86316 },
    { lat: 47.89356, lng: 106.85732 },
    { lat: 47.89881, lng: 106.85902 },
    { lat: 47.90576, lng: 106.85474 },
    { lat: 47.92031, lng: 106.85491 },
    { lat: 47.92232, lng: 106.86333 },
    { lat: 47.9237, lng: 106.89182 },
    { lat: 47.92831, lng: 106.90504 },
    { lat: 47.92992, lng: 106.92839 },
  ];

  const url = util.format('https://maps.googleapis.com/maps/api/js?key=%s', process.env.REACT_APP_GOOGLE_API_KEY);

  const DeliveryAreaComponent = compose(
    withProps({
      googleMapURL: url,
      loadingElement: <div style={{ height: '100%' }} />,
      containerElement: <div style={{ height: '400px' }} />,
      mapElement: <div style={{ height: '100%' }} />,
    }),
    withScriptjs,
    withGoogleMap,
  )(props => (
    <GoogleMap defaultZoom={12} defaultCenter={{ lat: 47.9185768, lng: 106.917666 }}>
      <Polygon
        path={coords}
        options={{
          fillColor: '#ffa500',
          fillOpacity: 0.35,
          strokeColor: '#ffa500',
          strokeOpacity: 0.8,
          strokeWeight: 2,
        }}
      />
    </GoogleMap>
  ));

  return ReactDOM.createPortal(
    <AriaModal
      titleText="Хүргэлтийн бүс"
      onExit={onClose}
      focusDialog
      escapeExits
      verticallyCenter
      underlayStyle={{ padding: '10px' }}
      underlayClickExits={false}
      dialogStyle={{ maxWidth: 500 }}
    >
      <div className="modal-content">
        <button
          type="button"
          aria-label="Close"
          onClick={onClose}
          className="closeButton"
        >
          <i className="svgIcon">
            <svg viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" fill="#5C272F">
              <path d="M6 4.65L1.626.278C1.26-.09.65-.093.278.277c-.375.376-.373.977 0 1.35L4.65 6 .278 10.374c-.367.367-.37.975 0 1.348.376.375.977.373 1.35 0L6 7.35l4.374 4.373c.367.367.975.37 1.348 0 .375-.376.373-.977 0-1.35L7.35 6l4.373-4.374c.367-.367.37-.975 0-1.348-.376-.375-.977-.373-1.35 0L6 4.65z" fillRule="evenodd" />
            </svg>
          </i>
        </button>
        <div className="modal-body">
          <header className="modal-header">
            <h2 className="modal-title">Хүргэлтийн бүс</h2>
          </header>
          <div className="alert alert-info" role="alert">
            Зөвхөн доорх хүрээнд хүргэлт хийгдэхийг анхаарна уу !
          </div>
          <div>
            <LazyLoad>
              <DeliveryAreaComponent />
            </LazyLoad>
          </div>
        </div>
      </div>
    </AriaModal>,
    document.body,
  );
}
