import React, { useState, useEffect } from 'react';
import axios from 'axios';
import qs from 'qs';
import Cookies from 'js-cookie';
import $ from 'jquery';
import LogRocket from 'logrocket';
import amplitude from 'amplitude-js';
import util from 'util';
import { useNavigate } from 'react-router-dom';
import { useUserOrders } from '../hooks/useUser';

export const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState(Cookies.get('accessToken'));
  const [user, setUser] = useState(null);
  const [loginErrorMessage, setLoginErrorMessage] = useState(null);
  const [toastText, setToastText] = useState(null);
  const [activeForm, setActiveForm] = useState('loginForm');
  const { userOrders } = useUserOrders(user?.id, token);

  const showLoginForm = (form) => {
    if (form !== 'loginForm') {
      setActiveForm('registrationForm');

      $('.modal-user').modal('show');

      return;
    }

    setActiveForm('loginForm');

    $('.modal-user').modal('show');
  };


  const navigate = useNavigate();

  const fetchUser = (accessToken) => {
    axios.defaults.baseURL = process.env.REACT_APP_API_URL;

    return axios.get('/me', {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.data;
      })
      .then((userInfo) => {
        setUser(userInfo);

        setToastText(util.format('Тавтай морилно уу %s', userInfo.firstname));
        setLoginErrorMessage(null);

        $('.modal-user').modal('hide');
        $('.login-toast').toast('show');

        // LogRocket identify
        LogRocket.identify(userInfo.id, {
          name: userInfo.firstname,
          username: userInfo.username,
        });

        // Amplitude identify
        amplitude.getInstance().setUserId(userInfo.username);
      })
      .catch(() => {
        setToken(null);
        setUser(null);
        Cookies.remove('accessToken');
      });
  };

  useEffect(() => {
    if (!token) {
      return;
    }
    fetchUser(token);
  }, []);

  const handleLogin = async (username, password) => {
    setLoading(true);
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

    const payload = {
      client_id: clientId,
      client_secret: clientSecret,
      username,
      password,
      grant_type: 'password',
    };

    return axios.post(
      '/oauth/v2/token',
      qs.stringify(payload),
    )
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.data;
      })
      .then(async ({ access_token }) => {
        const expirationTime = new Date(new Date().getTime() + 3600 * 1000);
        Cookies.set('accessToken', access_token, { expires: expirationTime });
        setToken(access_token);
        await fetchUser(access_token);
        $('.modal-user').modal('hide');
        setLoading(false);
      })
      .catch(() => {
        Cookies.remove('accessToken');
        setToken(null);
        setUser(null);
        setLoginErrorMessage('Нэр эсвэл нууц үг буруу байна');
        setLoading(false);
      });
  };

  const handleAuthorizationCode = async (code) => {
    const clientId = process.env.REACT_APP_CLIENT_ID;
    const clientSecret = process.env.REACT_APP_CLIENT_SECRET;
    const miniAppPlatform = process.env.REACT_APP_MINI_APP_PLATFORM;
    axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

    const payload = {
      client_id: clientId,
      client_secret: clientSecret,
      code,
    };

    return axios.get(
      `/oauth/v2/login/check-${miniAppPlatform}`,
      { params: payload },
    )
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }

        return response.data;
      })
      .then(async ({ access_token }) => {
        const expirationTime = new Date(new Date().getTime() + 3600 * 1000);
        Cookies.set('accessToken', access_token, { expires: expirationTime });
        setToken(access_token);
        await fetchUser(access_token);
      })
      .catch(() => {
        Cookies.remove('accessToken');
        setToken(null);
      });
  };

  const handleLogout = () => {
    setToken(null);
    Cookies.remove('accessToken');
    setUser(null);
    setToastText('Та системээс гарлаа');

    $('.login-toast').toast('show');
    navigate('/');
  };

  const value = {
    loading,
    token,
    user,
    onLogin: handleLogin,
    onLogout: handleLogout,
    toastText,
    loginErrorMessage,
    handleAuthorizationCode,
    activeForm,
    showLoginForm,
    userOrders,
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
