import React from 'react';

const Banner = () => (
  <div className="home-banner category-banner d-xl-block">
    <div className="home-banner-inner">
      <div className="banner-content">
        <div className="text text_1">Эрүүл, чанартай, шинэхэн </div>
        <h1>
          <span>бүтээгдэхүүнийг</span>
          <span>үйлдвэрлэгчээс</span>
        </h1>
        <div className="text text_2">хэрэглэгч танд шууд</div>
      </div>
    </div>
  </div>
);

export default Banner;
