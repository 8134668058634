import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import util from 'util';
import * as moment from 'moment';
import RestDataProvider from '../dataProvider/RestDataProvider';
import BackButton from './BackButton';
import 'moment/locale/mn';
import Coupon from './Coupon';
import useBasket from '../hooks/useBasket';
import useHub from '../hooks/useHub';

const Payment = ({ handleNavigationLink, handleLoading }) => {
  const [loading, setLoading] = useState(true);
  const [candy, setCandy] = useState(null);
  const [hipay, setHipay] = useState(null);
  const { basket, deliverySlot } = useBasket();
  const { hub } = useHub();

  const getPayment = () => {
    const psp = process.env.REACT_APP_MINI_APP_PLATFORM;
    const dataProvider = RestDataProvider(process.env.REACT_APP_API_URL);

    const url = util.format('orders/%d/payment', basket.id);
    dataProvider('list', url, { query: { psp } })
      .then((result) => {
        if (!result) {
          return;
        }

        // loading indicator of the page transiton
        handleLoading(false);
        setLoading(false);
        setCandy(result.candy);
        setHipay(result.hipay);
      });
  };

  useEffect(() => {
    getPayment();
  }, []);

  const checkCandyInvoice = () => {
    const candyInvoiceUrl = process.env.REACT_APP_CANDY_INVOICE_URL;
    const { invoiceNumber } = candy;
    window.location = `${candyInvoiceUrl}/${invoiceNumber}`;
  };

  const handlePayButton = (event) => {
    setLoading(true);
    event.preventDefault();

    const platform = process.env.REACT_APP_MINI_APP_PLATFORM;

    switch (platform) {
      case 'hipay':
        const { checkoutId } = hipay;
        window.hpsPayment(checkoutId);
        break;
      case 'candy':
      default:
        checkCandyInvoice();
    }
  };

  const {
    deliveryFee,
    deliveryMethod,
    coupon,
    displayTotal,
    fetchBasket,
  } = basket;
  const deliveryAddress = deliveryMethod.name === 'pick_up_from_hub' ? hub?.venue?.address : deliveryMethod.address;

  return (
    <div className="modal-body d-flex flex-column">
      <div className="title">
        <BackButton clickHandler={() => handleNavigationLink('chooseDelivery')} />
        <h4 className="basket-modal-title">
          <i className="fa fa-money" />
          <FormattedMessage id="basket_detail_payment" />
        </h4>
      </div>
      <div className="content order-information">
        <h4 className="sub-title"><FormattedMessage id="payment_order_summary" /></h4>
        <div className="box-gray">
          <div className="">
            <b><FormattedMessage id="payment_basket_title" /></b>
            <p>
              {basket.line_items.length}
              {' '}
              <FormattedMessage id="payment_basket_produce" />
            </p>
          </div>
          <div>
            <b>
              { basket.deliveryMethod.name === 'pick_up_from_hub' && (
                <FormattedMessage id="order_pickup_date" />
              )}

              { basket.deliveryMethod.name === 'home_delivery' && (
                <FormattedMessage id="payment_distribution_day" />
              )}
            </b>
            <p>
              { basket.deliveryMethod.name === 'pick_up_from_hub' && (
                <FormattedMessage
                  id="pickup_time_notice"
                  values={{
                    distributionDate: moment(deliverySlot.openAt).format('L'),
                    startTime: moment(deliverySlot.openAt).format('H'),
                    endTime: moment(deliverySlot.closeAt).format('H'),
                  }}
                />
              )}

              { basket.deliveryMethod.name === 'home_delivery' && (
                <FormattedMessage
                  id="order_delivery_notice"
                  values={{
                    distributionDate: moment(deliverySlot.openAt).format('L'),
                    startTime: moment(deliverySlot.openAt).format('H'),
                    endTime: moment(deliverySlot.closeAt).format('H'),
                  }}
                />
              )}
            </p>
          </div>
          <div>
            <b>
              { basket.deliveryMethod.name === 'pick_up_from_hub' && (
                <FormattedMessage id="delivery_form_address" />
              )}

              { basket.deliveryMethod.name === 'home_delivery' && (
                <FormattedMessage id="payment_venue_address" />
              )}
            </b>
            <p>
              {deliveryAddress}
            </p>
          </div>
        </div>
        <hr />
        <Coupon
          coupon={coupon}
          getBasket={fetchBasket}
          basket={basket}
          getPayment={getPayment}
        />
        <hr />
        <div className="cart-price-info">
          <div className="delivery-price d-flex justify-content-between">
            <b>
              <FormattedMessage id="basket_detail_delivery_service" />
              :
            </b>
            <div className="">{deliveryFee}</div>
          </div>
          { !!coupon
              && (
                <div className="discount-price price d-flex justify-content-between mb-3">
                  <p>Хямдарсан дүн:</p>
                  <div className="text-danger">
                    -
                    {coupon.discountTotal.amount}
                    {' '}
                    ₮
                  </div>
                </div>
              )
          }
          <div className="total-price price d-flex justify-content-between">
            <b>
              <FormattedMessage id="payment_total_price" />
              :
            </b>
            <div className="price">{displayTotal}</div>
          </div>
        </div>
      </div>
      <div className="footer mt-auto">
        <div>
          <button
            type="button"
            onClick={handlePayButton}
            disabled={loading ? 'disabled' : ''}
            className="btn btn-block btn-orange"
          >
            { loading && (
              <span className="spinner">
                <svg
                  width="20"
                  height="20"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                >
                  <path style={{ fill: '#fff' }} width="24" height="24" d="M 48 24C 48 37.2549 37.2549 48 24 48C 10.7451 48 0 37.2549 0 24C 0 10.7451 10.7451 0 24 0L 24 4C 12.9543 4 4 12.9543 4 24C 4 35.0457 12.9543 44 24 44C 35.0457 44 44 35.0457 44 24L 48 24Z" fillRule="evenodd" transform="rotate(119.94 24 24)">
                    <animateTransform attributeType="XML" attributeName="transform" type="rotate" from="0 24 24" to="360 24 24" dur="1.2s" repeatCount="indefinite" />
                  </path>
                </svg>
              </span>
            )}
            <span><FormattedMessage id="basket_detail_payment" /></span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Payment;
