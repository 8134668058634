import React from 'react';
import Autosuggest from 'react-autosuggest';
import what3words from 'w3w-javascript-wrapper';

const theme = {
  container: 'ms_suggestion__container',
  containerOpen: 'ms_suggestion__container--open',
  input: 'ms_suggestion__input',
  inputOpen: 'ms_suggestion__input--open',
  inputFocused: 'ms_suggestion__input--focused',
  itemsContainer: 'ms_suggestion__items-container',
  itemsContainerOpen: 'ms_suggestion__items-container--open',
  itemsList: 'ms_suggestion__items-list',
  item: 'ms_suggestion__item',
  itemFirst: 'ms_suggestion__item--first',
  itemHighlighted: 'ms_suggestion__item--highlighted',
  sectionContainer: 'ms_suggestion__section-container',
  sectionContainerFirst: 'ms_suggestion__section-container--first',
  sectionTitle: 'ms_suggestion__section-title',
};

function getSuggestionValue(suggestion) {
  return suggestion.words;
}

function renderSuggestion(suggestion) {
  return (
    <React.Fragment>
      <div className="what3words_address">
        <div className="logo">
          <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 32 32" xmlSpace="preserve"><path fill="currentColor" d="M10.7,4h2L4,28H2L10.7,4z M19.7,4h2L13,28h-2L19.7,4z M28.7,4h2L22,28h-2L28.7,4z" /></svg>
        </div>
        <div className="text">
          {suggestion.words}
        </div>
      </div>
      <div className="location">
        <div className="w3w-flags-mn" />
        <div className="name">{ suggestion.place }</div>
      </div>
    </React.Fragment>
  );
}

function renderSuggestionsContainer({ containerProps, children, query }) {
  containerProps.id = 'ms-suggestions-container';

  return (
    <div id="ms-suggestion-container" {... containerProps}>
      <div className="suggestion_result_label">Үр дүн</div>
      {children}
    </div>
  );
}

function renderInputComponent(inputProps) {
  return (
    <div className="input-group">
      <div className="input-group-prepend">
        <div className="input-group-text">
          <img src={require('../images/w3w.png')} alt="what3words" />
        </div>
      </div>
      <input {...inputProps} />
    </div>
  );
}

class AutoSuggest extends React.Component {
  constructor(props) {
    super(props);

    this.onChange = this.onChange.bind(this);
    this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
    this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);

    this.state = {
      value: props.words || '',
      suggestions: [],
    };
  }

  onChange(event, { newValue }) {
    this.setState({ value: newValue });
  }

  onSuggestionsFetchRequested({ value }) {
    const self = this;

    const options = {
      key: process.env.REACT_APP_W3W_API_KEY,
    };

    const w3w = new what3words.Geocoder(options);

    const centerOfUB = [47.9185083, 106.9174754];

    const params = {
      addr: value,
      lang: 'mn',
      country_filter: 'mn',
      focus: centerOfUB,
      clip: {
        type: 'focus',
        distance: 250,
      },
      count: 3,
    };

    const callback = {
      onSuccess(data) {
        if (!data.hasOwnProperty('suggestions')) {
          return [];
        }

        self.setState({
          suggestions: data.suggestions,
        });
      },
      onFailure(data) {
      },
    };

    w3w.autosuggest(params, callback);
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: [],
    });
  }

  onSuggestionSelected(event, {
    suggestion, suggestionValue, suggestionIndex, sectionIndex, method,
  }) {
    this.props.setFieldValue('what3Words', suggestionValue);
  }

  render() {
    const { value, suggestions } = this.state;

    const inputProps = {
      placeholder: 'хүргэх.газрын.хаяг (заавал бус)',
      value,
      className: 'form-control',
      onChange: this.onChange,
    };

    return (
      <Autosuggest
        theme={theme}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        onSuggestionSelected={this.onSuggestionSelected}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        renderSuggestionsContainer={renderSuggestionsContainer}
        renderInputComponent={renderInputComponent}
        inputProps={inputProps}
      />
    );
  }
}

export default AutoSuggest;
