import React from 'react';
import { FormattedMessage } from 'react-intl';
import * as moment from 'moment';
import 'moment/locale/mn';
import useHub from '../../hooks/useHub';

const UserOrderModal = ({ order }) => {
  const { hub } = useHub();
  const lineItems = order.line_items;
  const { deliverySlot, deliveryMethod } = order;

  return (
    <div className="modal product fade" id={`modal-user-order${order.id}`} role="dialog" aria-hidden="false">
      <div className="modal-dialog" role="document">
        <div className="modal-content p-4">
          <button type="button" className="close" data-dismiss="modal">&times;</button>
          <div id="ms-basket-modal-content" className="modal-body d-flex flex-column">
            <div className="content order-information pb-0">
              <div className="alert alert-success" role="alert">
                <p>
                  <b>
                    Захиалгын дугаар:
                    <span className="badge badge-success">{order.id}</span>
                  </b>
                </p>
                <p>
                  <h5>
                    <FormattedMessage id="payment_distribution_day" />
                    :
                  </h5>
                  <strong>
                    {moment(deliverySlot.openAt).format('L')}
                    ,
                    {moment(deliverySlot.openAt).format('dddd')}
                    ,
                  </strong>
                  &nbsp;
                  {moment(deliverySlot.openAt).format('H')}
                  {' '}
                  -
                  {moment(deliverySlot.closeAt).format('H')}
                  {' '}
                  хооронд
                </p>
                <p>
                  <h5>Хүргэлтийн хаяг:</h5>
                  {
                    deliveryMethod.name === 'pick_up_from_hub'
                      ? hub?.venue?.address
                      : deliveryMethod.address === null
                        ? deliveryMethod.what3Words
                        : deliveryMethod.address
                  }
                </p>
              </div>
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th scope="col">Бүтээгдэхүүний нэр</th>
                    <th scope="col">Тоо</th>
                    <th scope="col">Үнэ</th>
                  </tr>
                </thead>
                <tbody>
                  { lineItems.map(lineItem => (
                    <tr key={lineItem.id}>
                      <td>{lineItem.productName}</td>
                      <td>{lineItem.quantity}</td>
                      <td>{lineItem.displayTotal}</td>
                    </tr>
                  ))}
                  { !!order.coupon
                      && (
                        <tr>
                          <td />
                          <td>Купон:</td>
                          <td>
                            <p className="text-danger">
                              -
                              {order.coupon.discountTotal.amount}
                              {' '}
                              ₮
                            </p>
                          </td>
                        </tr>
                      )
                  }
                  <tr>
                    <td />
                    <td>Нийт:</td>
                    <td>
                      {order.totalPriceAmount}
                      {' '}
                      ₮
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserOrderModal;
