import React, { useEffect } from 'react';
import util from 'util';
import RestDataProvider from '../dataProvider/RestDataProvider';
import DeliveryMethodForm from './DeliveryMethodForm';
import useBasket from '../hooks/useBasket';
import useAuth from '../hooks/useAuth';
import useHub from '../hooks/useHub';

const ChooseDelivery = ({ handleNavigationLink, handleLoading, showDeliveryArea }) => {
  const { basket, fetchBasket, deliverySlot } = useBasket();
  const { user } = useAuth();
  const { hub } = useHub();
  const deliveryMethod = basket?.deliveryMethod;
  const basketId = basket?.id;
  const totalPrice = basket?.totalPrice;

  useEffect(() => {
    handleLoading(false);
  }, []);

  const handleDeliveryMethod = (values) => {
    handleLoading(true);
    const data = {
      order_delivery_method: {
        name: values.deliveryMethod,
        phoneNumber: values.phoneNumber,
        address: values.address,
        what3Words: values.what3Words,
      },
    };

    const dataProvider = RestDataProvider(process.env.REACT_APP_API_URL);
    const url = util.format('orders/%d/delivery_method', basketId);
    dataProvider('create', url, { data })
      .then(() => {
        handleNavigationLink('payment');
        fetchBasket();
      });
  };

  return (
    <DeliveryMethodForm
      handleDeliveryMethod={handleDeliveryMethod}
      handleNavigationLink={() => handleNavigationLink('deliverySlot')}
      deliverySlot={deliverySlot}
      hub={hub}
      deliveryMethod={deliveryMethod.name}
      deliveryAddress={deliveryMethod.address}
      deliveryPhoneNumber={deliveryMethod.phoneNumber}
      what3Words={deliveryMethod.what3Words}
      user={user}
      totalPrice={totalPrice}
      showDeliveryArea={showDeliveryArea}
    />
  );
};

export default ChooseDelivery;
