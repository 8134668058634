import React from 'react';

const RegistrationForm = () => (
  <div className="modal-body">
    <div className="auth-form-header text-center">
      <h4>Бүртгүүлэх</h4>
    </div>
    <form name="fos_user_registration_form" method="post" action="/mn/register/" className="auth-form needs-validation">
      <div className="form-group form-row">
        <div className="col ">
          <label className="control-label required" htmlFor="fos_user_registration_form_lastName">Овог</label>
          <input type="text" id="fos_user_registration_form_lastName" name="fos_user_registration_form[lastName]" required="required" className="form-control" />
          <span className="help-block" />
        </div>
        <div className="col ">
          <label className="control-label required" htmlFor="fos_user_registration_form_firstName">Нэр</label>
          <input type="text" id="fos_user_registration_form_firstName" name="fos_user_registration_form[firstName]" required="required" className="form-control" />
          <span className="help-block" />
        </div>
      </div>
      <div className="form-group ">
        <label className="control-label required" htmlFor="fos_user_registration_form_email">И-мэйл</label>
        <input type="email" id="fos_user_registration_form_email" name="fos_user_registration_form[email]" required="required" className="form-control" />
        <span className="help-block" />
      </div>
      <div className="form-group ">
        <label className="control-label required" htmlFor="fos_user_registration_form_plainPassword">Нууц үг</label>
        <input type="password" id="fos_user_registration_form_plainPassword" name="fos_user_registration_form[plainPassword]" required="required" className="form-control" />
        <span className="help-block" />
      </div>
      <div className="form-group form-row">
        <div className="col ">
          <label className="control-label required" htmlFor="fos_user_registration_form_address_district">Дүүрэг</label>
          <select id="fos_user_registration_form_address_district" name="fos_user_registration_form[address][district]" required="required" className="custom-select" data-locale="mn">
            <option value="">Сонгоно уу</option>
            <option value="baganuur">Багануур</option>
            <option value="bagakhangai">Багахангай</option>
            <option value="bayangol">Баянгол</option>
            <option value="bayanzurkh">Баянзүрх</option>
            <option value="nalaikh">Налайх</option>
            <option value="songino-khairkhan">Сонгоно Хайрхан</option>
            <option value="sukhbaatar">Сүхбаатар</option>
            <option value="khan-uul">Хан-Уул</option>
            <option value="chingeltei">Чингэлтэй</option>
          </select>
          <span className="help-block" />
        </div>
        <div className="col ">
          <label className="control-label required" htmlFor="fos_user_registration_form_address_khoroo">Хороо</label>
          <select id="fos_user_registration_form_address_khoroo" name="fos_user_registration_form[address][khoroo]" required="required" className="custom-select">
            <option value="">Сонгоно уу</option>
            <option value="1">1-р хороо</option>
            <option value="2">2-р хороо</option>
            <option value="3">3-р хороо</option>
            <option value="4">4-р хороо</option>
            <option value="5">5-р хороо</option>
            <option value="6">6-р хороо</option>
            <option value="7">7-р хороо</option>
            <option value="8">8-р хороо</option>
            <option value="9">9-р хороо</option>
            <option value="10">10-р хороо</option>
            <option value="11">11-р хороо</option>
            <option value="12">12-р хороо</option>
            <option value="13">13-р хороо</option>
            <option value="14">14-р хороо</option>
            <option value="15">15-р хороо</option>
            <option value="16">16-р хороо</option>
            <option value="17">17-р хороо</option>
            <option value="18">18-р хороо</option>
            <option value="19">19-р хороо</option>
            <option value="20">20-р хороо</option>
            <option value="21">21-р хороо</option>
            <option value="22">22-р хороо</option>
            <option value="23">23-р хороо</option>
            <option value="24">24-р хороо</option>
            <option value="25">25-р хороо</option>
            <option value="26">26-р хороо</option>
            <option value="27">27-р хороо</option>
            <option value="28">28-р хороо</option>
            <option value="29">29-р хороо</option>
            <option value="30">30-р хороо</option>
            <option value="31">31-р хороо</option>
            <option value="32">32-р хороо</option>
          </select>
          <span className="help-block" />
        </div>
      </div>
      <div className="form-group p-2">
        <div className="form-check ">
          <input type="checkbox" id="fos_user_registration_form_termsOfUse" name="fos_user_registration_form[termsOfUse]" required="required" className="form-check-input" value="1" />
          <label htmlFor="fos_user_registration_form_termsOfUse" className="form-check-label">
            Би Монгол Шимийн
            {' '}
            <a rel="noopener noreferrer" target="_blank" href="https://mongolshim.mn/terms/mongolshim_terms_of_use.pdf">үйлчилгээний нөхцөлтэй</a>
            {' '}
            танилцсан бөгөөд хүлээн зөвшөөрч байна.
          </label>
          <span className="help-block" />
        </div>
      </div>
      <div className="form-group">
        <input type="submit" className="btn btn-warning btn-orange btn-block btn-auth" value="Бүртгүүлэх" />
      </div>
    </form>
    <div className="p-4 text-center">
      Бүртгэлтэй бол
      {' '}
      <a href="/mn/login">энд дарж</a>
      {' '}
      нэвтэрнэ үү.
    </div>
  </div>
);

export default RegistrationForm;
