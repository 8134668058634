import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';
import amplitude from 'amplitude-js';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'simple-line-icons/css/simple-line-icons.css';
import './index.css';
import './App.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'popper.js/dist/umd/popper.min.js';
import './legacy-main.js';
import * as serviceWorker from './serviceWorker';
import rootReducer from './reducers';
import LocalDataProvider from './dataProvider/LocalDataProvider';
import HubProvider from './context/hubProvider';
import AuthProvider from './context/authProvider';
import BasketProvider from './context/basketProvider';
import ScrollToTop from './utils/ScrollToTop';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
  });

  LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID);

  amplitude.getInstance().init(process.env.REACT_APP_INTEGRATION_AMPLITUDE_ID);
  amplitude.getInstance().logEvent('Mini-app connected!');
}

const store = createStore(rootReducer);
const lastVisitedHub = LocalDataProvider.getLastVisitedHub();

ReactDOM.render(
  <Provider store={store}>
    <HubProvider hubId={lastVisitedHub}>
      <BrowserRouter>
        <AuthProvider>
          <BasketProvider>
            <ScrollToTop>
              <App />
            </ScrollToTop>
          </BasketProvider>
        </AuthProvider>
      </BrowserRouter>
    </HubProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
